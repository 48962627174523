<section class="landing">
  <div class="wrapper">
    <div class="bg">
      <picture>
        <source
          media="(min-width:800px)"
          [srcset]="
            sanitizeImageUrl(
              '../../../../assets/Images/Home/Landing/1280/Digitalbyrå-i-oslo-Moderna-Media-for-grafisk-design-logo-design-og-web-design-hjemmeside-for-bedrift.webp'
            )
          "
        />
        <source
          media="(min-width:0px)"
          [srcset]="
            sanitizeImageUrl(
              '../../../../assets/Images/Home/Landing/640/Digitalbyrå-i-oslo-Moderna-Media-for-grafisk-design-logo-design-og-web-design-hjemmeside-for-bedrift.webp'
            )
          "
        />
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Home/Landing/1280/Digitalbyrå-i-oslo-Moderna-Media-for-grafisk-design-logo-design-og-web-design-hjemmeside-for-bedrift.webp'
            )
          "
          alt="Moderna media Digitalbyrå for bedriftens nettsider, programvare, grafisk design og søkemotoroptimalisering"
          class="landing-image"
        />
      </picture>
      <div class="bg-shade"></div>
    </div>
    <div class="content">
      <div class="titles">
        <p class="super-title">Nettsider, Design og SEO</p>
        <p class="super-title bg">Nettsider, Design og SEO</p>
        <h1 class="subtitle large">
          Øk din digitale tilstedeværelse, opplev vekst og oppnå dine mål med
          Moderna Media Digitalbyrå
        </h1>
      </div>
      <!-- <div class="info">
        Ikke vent med å investere i din bedrift. Vi hjelper deg å øke
        omsettningen til din bedrift ved å gjøre deg synlig på nett.
      </div> -->
      <div class="buttons">
        <button class="btn cta" (click)="scrollToId('kontakt')">
          <p>Book et uforpliktet møte</p>
        </button>
      </div>
    </div>
  </div>
</section>
