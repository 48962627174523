<section
  class="prices-section"
  aria-labelledby="prices-section-title"
  aria-label="Våre priser"
>
  <div class="wrapper">
    <header class="prices-section-header">
      <p class="subtitle">Verdi for pengene</p>
      <h2 class="title" id="prices-section-title">Våre priser</h2>
      <p class="header-content">
        Invester i virksomheten din med tjenester med høy kvalitet og som skaper
        verdi og vekst. Alt vi leverer er bygget fra bunnen av, med
        drømmekundene dine i fokus og vår 100% fornøyelsesgaranti.
      </p>
    </header>
    <div class="price-container">
      <!-- Nettsider -->
      <a
        class="price-box-anchor-wrapper"
        routerLink="/tjenester/bedrift/utvikling/hjemmeside-bedrift"
        title="Les mer om hjemmesider for bedrift"
      >
        <section
          class="price-box"
          aria-labelledby="price-box-title-nettside"
          aria-label="Nettside for bedrifter"
        >
          <header>
            <div class="header-content-container">
              <h3 class="price-box-title" id="price-box-title-nettside">
                Nettside
              </h3>
              <p class="price-box-content">
                Den digitale broen mellom deg og dine kunder
              </p>
              <div class="les-mer">
                <p>Les mer</p>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/right-arrow-white.svg'
                    )
                  "
                  role="presentation"
                  alt=""
                />
              </div>
            </div>
            <div class="bg">
              <div class="shadow"></div>
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Home/prices/450/Hjemmeside for bedrift pris.webp'
                  )
                "
                alt="Billige hjemmesider for bedrift som gjør deg synlig og top of mind uten SEO"
              />
            </div>
          </header>
          <div class="content">
            <ul class="usp-list">
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Responsivt design for optimal SEO</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Skreddersydd for din bedrift</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Optimalisert for salg og representasjon</p>
              </li>
            </ul>
            <p class="info">
              En profesjonell og målrettet nettside tiltrekker oppmerksomhet,
              fremhever det beste du har å tilby og bygger autentiske relasjoner
              med kundene dine.
            </p>
            <div class="price-box-price">
              <p class="price">
                Fra {{ Prices.Utvikling.Nettside[1].price }}kr
              </p>
            </div>
          </div>
        </section>
      </a>
      <!-- Programvare -->
      <a
        class="price-box-anchor-wrapper"
        routerLink="/tjenester/bedrift/utvikling/programvare"
        title="Les mer om programvare for bedrift"
      >
        <section
          class="price-box"
          aria-labelledby="price-box-title-programvare"
          aria-label="Software for bedrifter"
        >
          <header>
            <div class="header-content-container">
              <h3 class="price-box-title" id="price-box-title-programvare">
                Programvare
              </h3>
              <p class="price-box-content">
                Gjør hverdagen enklere for deg og dine kunder gjennom
                automatisering
              </p>
              <div class="les-mer">
                <p>Les mer</p>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/right-arrow-white.svg'
                    )
                  "
                  role="presentation"
                  alt=""
                />
              </div>
            </div>
            <div class="bg">
              <div class="shadow"></div>
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Home/prices/450/Programvare for bedrifter pris.webp'
                  )
                "
                alt="Billige hjemmesider for bedrift som gjør deg synlig og top of mind uten SEO"
              />
            </div>
          </header>
          <div class="content">
            <ul class="usp-list">
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Automatiserte jobber</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Synkronisering gjennom API</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Skreddersydd for din bedrift</p>
              </li>
            </ul>
            <p class="info">
              Et datasystem kan hjelpe deg med alt fra bordreservasjon til
              logistikkhåndtering. Spar bedriften for unødvendige kostnader,
              skreddersy din programvare å automatiser dine dagilge gjøremål.
            </p>
            <div class="price-box-price">
              <p class="price">
                Fra {{ Prices.Utvikling.Programvare[1].price }}kr
              </p>
            </div>
          </div>
        </section>
      </a>
      <!-- Design -->
      <a
        class="price-box-anchor-wrapper"
        routerLink="/tjenester/bedrift/design"
        title="Les mer om design tjenester for bedrifter"
      >
        <section
          class="price-box"
          aria-labelledby="price-box-title-design"
          aria-label="Design tjenster for bedrifter"
        >
          <header>
            <div class="header-content-container">
              <h3 class="price-box-title" id="price-box-title-design">
                Design
              </h3>
              <p class="price-box-content">
                Skill deg ut i markedet gjennom minneverdig design
              </p>
              <div class="les-mer">
                <p>Les mer</p>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/right-arrow-white.svg'
                    )
                  "
                  role="presentation"
                  alt=""
                />
              </div>
            </div>
            <div class="bg">
              <div class="shadow"></div>
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Home/prices/450/Grafisk Design tjenester priser.webp'
                  )
                "
                alt="Billige hjemmesider for bedrift som gjør deg synlig og top of mind uten SEO"
              />
            </div>
          </header>
          <div class="content">
            <ul class="usp-list">
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Logo design</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Web design</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Grafisk design</p>
              </li>
            </ul>
            <p class="info">
              Ved å skape en profesjonell visuell identitet kan du påvirke
              hvordan kundene oppfatter merkevaren din og skille deg ut fra
              konkurrentene dine.
            </p>
            <div class="price-box-price">
              <p class="price">Fra {{ Prices.Design.Logo[1].price }}kr</p>
            </div>
          </div>
        </section>
      </a>
      <!-- SEO -->
      <a
        class="price-box-anchor-wrapper"
        routerLink="/tjenester/bedrift/seo/søkemotoroptimalisering"
        title="Les mer om Søkemotoroptimalisering for bedriften din"
      >
        <section
          class="price-box"
          aria-labelledby="price-box-title-seo"
          aria-label="SEO for bedrifter"
        >
          <header>
            <div class="header-content-container">
              <h3 class="price-box-title" id="price-box-title-seo">SEO</h3>
              <p class="price-box-content">
                Gjør det enkelt for kundene dine å finne deg
              </p>
              <div class="les-mer">
                <p>Les mer</p>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/right-arrow-white.svg'
                    )
                  "
                  role="presentation"
                  alt=""
                />
              </div>
            </div>
            <div class="bg">
              <div class="shadow"></div>
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Home/prices/450/Søkemotoroptimalisering byrå.webp'
                  )
                "
                alt="Billige hjemmesider for bedrift som gjør deg synlig og top of mind uten SEO"
              />
            </div>
          </header>
          <div class="content">
            <ul class="usp-list">
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Teknisk- & Off-page SEO</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Innholdsproduksjon</p>
              </li>
              <li>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Icons/home/check-mark.svg'
                    )
                  "
                  alt=""
                  role="presentation"
                />
                <p>Målbare resultater</p>
              </li>
            </ul>
            <p class="info">
              Ranger høyt i Google, bli funnet først og øk trafikken til
              nettsiden din. Med fokus på SEO kan du få inn gratis trafikk fra
              potensielle kunder som søker etter det du har å tilby.
            </p>
            <div class="price-box-price">
              <p class="price">
                Fra {{ Prices.SEO.Innholdsproduksjon[1].price }}kr
              </p>
            </div>
          </div>
        </section>
      </a>
    </div>
  </div>
</section>
