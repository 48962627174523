<section
  class="landing"
  aria-labelledby="landing-title"
  aria-label="Hjemmeside for bedrifter"
>
  <div class="wrapper">
    <div class="bg">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/1280/Liten bedrift som bruker en betalingsløsning.webp'
          )
        "
        alt="Moderna media digitalbyrå for bedriftens nettsider, bedriftsystemer, grafisk design og logo design"
        class="landing-image"
      />
      <div class="bg-shade"></div>
    </div>
    <div class="content">
      <div class="titles">
        <h1 class="tjenester-title" id="landing-title">
          <span class="super-title">Programvare</span>
          <span class="subtitle large">For bedrifter</span>
        </h1>
        <p class="tjenester-title background">
          <span class="super-title bg">Programvare</span>
        </p>
      </div>
      <div class="info">
        <p>
          Vi utvikler skreddersydde programvarer for din bedrift, med
          automatiserte løsninger av daglige gjøremål. Våre programvarer er bygd
          for å spare kostnader, generere inntekt, og øke brukervennlighet.
        </p>
      </div>
      <div class="buttons">
        <a class="btn cta shadow" href="#kontakt"
          ><p>Book et uforpliktet møte</p></a
        >
        <a class="btn cta-2" href="#tjenester"><p>Les om hjemmesider</p></a>
      </div>
    </div>
    <ul class="links">
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('hjemmeside')">
          Programvare for bedriften
        </button>
      </li>
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('bedrifthjemmeside')">
          Hvorfor programvare
        </button>
      </li>
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('neste-steg')">
          Dine neste steg
        </button>
      </li>
      <li class="link-container">
        <button
          class="header-link"
          (click)="scrollToId('inkluderte-tjenester')"
        >
          inkluderte tjenester
        </button>
      </li>
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('priser')">
          priser
        </button>
      </li>
    </ul>
  </div>
</section>

<section
  class="info white"
  aria-label="Hvorfor din bedrift trenger en hjemmeside"
  id="hjemmeside"
>
  <div class="info-container right">
    <div class="image-container">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/640/App utvikling for bedrift - system som brukes på ipad med apple pen.webp'
          )
        "
        alt=""
      />
    </div>
    <div class="text-container">
      <article aria-label="den beste hjemmesiden for bedriften">
        <div class="header">
          <p class="subtitle-gradient blue">Spar kostnader med</p>
          <h2 class="title-bold">Programvare for bedriften</h2>
        </div>
        <p class="text-section">
          En programvare omfatter alle programmer, prosedyrer og rutiner
          tilknyttet driften av et datasystem. Kort fortalt, kan en programvare
          fungere som en automatisering eller digitalisering av bedrifters
          daglige gjøremål. Eksempler på eksisterende programvarer er Photoshop,
          Word, og Microsoft Teams. Programvarer kan bli delt inn i to
          kategorier, applikasjonsprogramvarer og systemprogramvarer. Moderna
          Media utvikler applikasjonsprogramvarer, som inkluderer alt fra
          spillapplikasjoner, databasesystemer, og pedagogiske programvarer.
        </p>
        <p class="text-section">
          I dagens travle samfunn, har de fleste bedrifter innsett at tid er
          penger. Jo mere tid dine ansatte bruker på daglige gjøremål, desto
          høyere blir utgiftene, og desto dårligere blir kundeopplevelsen.
          Derfor har flere og flere bedrifter behov for å automatisere, eller
          digitalisere daglige gjøremål ved hjelp av programvarer. Enten
          bedriften din har bruk for et reservasjonssystem, analyse plattform,
          bestillingssystem, regnskapssystem, chattesystem, eller noe annet, vil
          en skreddersydd programvare være den beste løsningen!
        </p>
        <div class="list">
          <h3>Programvare som kan hjelpe bedriften din å nå nye høyder:</h3>
          <ul>
            <li>Automatisering av daglige gjøremål</li>
            <li>Skaper god kundeopplevelse</li>
            <li>Øker tillit hos kunder</li>
            <li>Øker produktivitet hos ansatte</li>
          </ul>
        </div>
        <p class="text-section">
          Invester i en neste-generasjons programvare for bedriften din! Ta
          kontakt med oss i Moderna Media i dag.
        </p>
      </article>
      <div class="buttons">
        <a href="#kontakt" class="btn cta shadow"
          ><p>Book et uforpliktet møte</p></a
        >
        <a href="#kontakt" class="btn cta-2 shadow"
          ><p>Be om tilbud på programvare</p></a
        >
      </div>
    </div>
  </div>
</section>

<section
  class="usp-cards"
  aria-labelledby="usp-cards-title"
  id="bedrifthjemmeside"
>
  <div class="usp-cards-wrapper">
    <div class="titles">
      <p class="subtitle">Hvorfor du trenger</p>
      <h2 class="subtitle-gradient blue" id="usp-cards-title">
        Skreddersydd programvare for bedriften
      </h2>
    </div>
    <div class="cards">
      <div class="card">
        <div class="image">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/640/dame som sitter på restaurant med programvareutvikling.webp'
              )
            "
            alt=""
          />
        </div>
        <div class="content">
          <h3>Skreddersydd programvare gir deg skalerbarhet.</h3>
          <p>
            En ferdigpakket programvare er bygget for å passe alle digitale
            plattformer. Noe som kan bli sammenlignet med en universell
            størrelse. Dette blir problematisk når bedriften din vokser ut av
            programvaren, eller behøver og kutte ned. Det din, og alle bedrifter
            trenger er jo en programvare tilpasset sine spesifikke behov, mål,
            og forventet vekst. Når din bedrift bestiller en skreddersydd
            programvare hos Moderna Media får du nøyaktig den skalerbarheten du
            behøver.
          </p>
        </div>
      </div>
      <div class="card">
        <div class="image">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/640/mann som utvikler programvare.webp'
              )
            "
            alt=""
          />
        </div>
        <div class="content">
          <h3>Design en unik programvare for bedriften din</h3>
          <p>
            Sammenlignet med en ferdigpakket programvare, som ofte koster og gir
            deg mer en du behøver, er en skreddersydd programvare
            kostnadseffektiv, og utviklet for dine behov. I tillegg kan en
            skreddersydd programvare øke bedriftens synlighet på nett, og
            effektivitet med å oppgradere markedsføringstaktikk. På denne måten
            fører en skreddersydd programvare til nye kunder og høyere
            inntekter. Noe enhver bedrift ønsker!
          </p>
        </div>
      </div>
      <div class="card">
        <div class="image">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/640/Dame som handler gjennom ett betalingssystem.webp'
              )
            "
            alt=""
          />
        </div>
        <div class="content">
          <h3>
            Skreddersydd programvare gjør bedriften din konkurransedyktig.
          </h3>
          <p>
            Det er ikke alle bedrifter i ditt fagfelt som har en skreddersydd
            programvare. Derfor vil en skreddersydd programvare gjøre bedriften
            din mer konkurransedyktig. Enten du behøver en app for å kontakte
            kundene dine, eller et bookingsystem, vil en skreddersydd
            programvare skille deg fra konkurransen.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="usp-info" aria-labelledby="usp-info-title">
  <div class="usp-info-wrapper">
    <div class="header">
      <img
        src="../../../../assets/Images/Icons/tjenester/services/programvare.svg"
        alt=""
      />
      <h2 class="subtitle-gradient" id="usp-info-title">
        Skap din unike programvare for bedriften din
      </h2>
    </div>
    <div class="content">
      <p>
        De fleste organisasjoner søker skreddersydde programvarer for å
        optimalisere driften sin. Enten det er en enkel database, eller en
        kompleks løsning, er en skreddersydd programvare alltid det beste. Men
        evnen til å bygge en effektiv programvare holder ikke. Selv om Moderna
        Medias eksperter kan designe fantastiske løsninger, vil du også delta i
        prosessen ved å presentere dine krav og visjoner. Dermed vil du være med
        på å designe en programvare med din egen kreative tilnærming i ditt
        fagfelt.
      </p>
    </div>
  </div>
</section>

<section class="process" aria-labelledby="process-title" id="neste-steg">
  <div class="wrapper">
    <div class="image">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/640/Mann som ser på programvare for bedriften.webp'
          )
        "
        alt="Digitalbyrå som jobber med bedrift nettsider og design"
      />
    </div>
    <div class="content">
      <div class="content-wrapper">
        <div class="titles">
          <h2 class="title" id="process-title">
            Våre neste steg mot skreddersydd programvare
          </h2>
          <p class="innhold">
            For å få en god forståelse av dine behov ønsker vi at du kontakter
            oss for et uforpliktet møte. I møte med oss vil det være mulig å be
            om et prisforslag. Uavhengig av hvilken kontaktmetode du bruker tar
            vi de neste stegene mot en skreddersydd programvare. Dermed vil vi
            ha første utkast klart innen en uke.
          </p>
        </div>
        <div class="liste">
          <div class="point">
            <div class="number">1</div>
            <div class="point-content">
              <p class="point-title">Kontakt oss</p>
              <p class="point-text">
                Klikker du på knappen for et uforpliktet møte vil vi kontakte
                deg snarest via telefon, mail eller sms, basert på din
                preferanse. Om du ønsker et tilbud må du fylle ut alle feltene
                slik at vi får en god forståelse av dine ønsker og behov.
              </p>
            </div>
          </div>
          <div class="point">
            <div class="number">2</div>
            <div class="point-content">
              <p class="point-title">Vi kontakter deg</p>
              <p class="point-text">
                Etter en gjennomgang av dine behov, tegner vi en plan og
                diskuterer den med deg. Deretter gjør vi nødvendige endringer
                slik at vi kan låse planen før vi starter utviklingsprosessen.
              </p>
            </div>
          </div>
          <div class="point">
            <div class="number">3</div>
            <div class="point-content">
              <p class="point-title">Leveranse</p>
              <p class="point-text">
                Gjennom utviklingsprosessen vil vi gi deg ukentlige
                oppdateringer. Dersom du under prosessen ikke er fornøyd, har vi
                en 100% fornøydgaranti som gjør at du kan kansellere oppdraget
                når som helst.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  class="usp-card-list"
  aria-labelledby="usp-list-title"
  id="inkluderte-tjenester"
>
  <div class="usp-list-wrapper">
    <div class="header">
      <p class="subtitle">Våre gratis inkluderte tjenester</p>
      <h2 class="subtitle-gradient blue" id="usp-list-title">
        Alle våre for hjemmesider inkluderer disse tjenestene helt gratis
      </h2>
    </div>
    <div class="cards">
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/google analytics.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Integrasjon av programvare</h3>
        <p>Vi integrerer din nye programvare mot din gamle. Helt gratis.</p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/google my business.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Installasjon</h3>
        <p>Vi setter opp og installerer ditt nye programvare. Helt gratis.</p>
      </div>
    </div>
  </div>
</section>

<section class="prices" aria-labelledby="prices-title" id="priser">
  <div class="wrapper">
    <div class="titles">
      <p class="subtitle">Verdi for pengene</p>
      <h2 class="subtitle-gradient blue" id="prices-title">
        Våre priser på programvare for bedriften.
      </h2>
      <!-- <p class="content">
        Vi starter alle prosesser med et budsjettmøte hvor vi blir enige om krav
        og ønsker.
      </p> -->
    </div>
    <div class="prices-wrapper">
      <div class="price-component">
        <div class="img-wrapper">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/400/Enkel programvare for bedrift.webp'
              )
            "
            alt="Logo for liten bedrift som henger på skilt"
          />
          <h3 class="usp-title">Enkel programvare</h3>
        </div>
        <div class="content-wrapper">
          <div class="info">
            <p>
              Vi definerer enkel programvare som programvare som tar under 75
              timer å utvikle. Ettersom all programvare er skreddersydd og
              individuell per bedrift er det veldig vanskelig å prissette
              programvare før vi har hatt ett møte.
            </p>
          </div>
          <div class="price">
            <p>Fra 25.000kr</p>
          </div>
        </div>
      </div>
      <div class="price-component">
        <div class="img-wrapper">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/programvare/400/avansert programvare for bedrift.webp'
              )
            "
            alt="Liten bedrift som blant annet restaurant"
          />
          <h3 class="usp-title">Avansert programvare</h3>
        </div>
        <div class="content-wrapper">
          <div class="info">
            <p>
              All programvare som krever mer enn 75 timer å utvikle beregner vi
              som avansert programvare. Vi tar for tiden ikke på oss oppdrag som
              krever mer enn 500 timer å utvikle. Ta en prat med oss så
              esitmerer vi prosjektet og budsjettet ditt for å gi deg ett best
              mulig tilbud!
            </p>
          </div>
          <div class="price">
            <p>Fra 50.000kr</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-about-your-next-project></app-about-your-next-project>
