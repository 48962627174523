<section
  class="landing"
  aria-labelledby="landing-title"
  aria-label="Hjemmeside for bedrifter"
>
  <div class="wrapper">
    <div class="bg">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/utvikling av nettsider for bedrift.jpg'
          )
        "
        alt="Moderna media digitalbyrå for bedriftens nettsider, bedriftsystemer, grafisk design og logo design"
        class="landing-image"
      />
      <div class="bg-shade"></div>
    </div>
    <div class="content">
      <div class="titles">
        <h1 class="tjenester-title" id="landing-title">
          <span class="super-title">Hjemmeside</span>
          <span class="subtitle large">For bedrifter</span>
        </h1>
        <p class="tjenester-title background">
          <span class="super-title bg">Hjemmeside</span>
        </p>
      </div>
      <div class="info">
        <p>
          Moderne og profesjonelle nettsider kodet/designet fra bunnen av
          Sikkerhetsorienterte, tilpasset alle skjermstørrelser og utviklet med
          dine idealkunder i fokus
        </p>
      </div>
      <div class="buttons">
        <a class="btn cta shadow" href="/pris"
          ><p>Få et uforpliktet pristilbud</p></a
        >
      </div>
    </div>
    <ul class="links">
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('hjemmeside')">
          Våre nettsider
        </button>
      </li>
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('hjemmeside-2')">
          Dine fordeler
        </button>
      </li>
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('neste-steg')">
          Hvorfor Moderna Media
        </button>
      </li>
      <li class="link-container">
        <button
          class="header-link"
          (click)="scrollToId('inkluderte-tjenester')"
        >
          Tjenester
        </button>
      </li>
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('priser')">
          Dine neste steg
        </button>
      </li>
      <li class="link-container">
        <button class="header-link" (click)="scrollToId('priser')">
          Priser
        </button>
      </li>
    </ul>
  </div>
</section>

<section
  class="info white"
  aria-label="Bygg din bedrifthjemmeside"
  id="hjemmeside"
>
  <div class="info-container left">
    <div class="image-container">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Dame som utvikler nettside på wix.jpg'
          )
        "
        alt=""
      />
    </div>
    <div class="text-container">
      <article aria-label="den beste hjemmesiden for bedriften">
        <div class="header">
          <p class="subtitle-gradient blue">Våre nettsider</p>
          <h2 class="title-bold">Hvordan bygge din bedrift på nett?</h2>
        </div>
        <p class="text-section">
          Din bredriftsnettside er den viktigste og ofte den eneste digitale
          kanalen sett av kundene dine. Mangler du en hjemmeside er det større
          risiko for at din idelle kunde aldri finner bedriften din.
        </p>
        <p class="text-section">
          Akkurat som når man planlegger konstruksjon av en fysisk butikk eller
          et forretningslokale hvor man kan treffe potensielle kunder er det en
          del viktige faktorer å tenke på. De inkluderer:
        </p>
        <div class="list">
          <!-- <h3></h3> -->
          <ul>
            <li>Oppsett og layout</li>
            <li>Funksjonalitet</li>
            <li>Beliggenhet</li>
            <li>Synlighet</li>
            <li>Sikkerhet</li>
            <li>Pris</li>
          </ul>
        </div>
        <p class="text-section">
          Den må i tillegg se bra nok ut til og være noe du er stolt av, siden
          førsteinntrykket er svært viktig: Din visuell identitet betyr mye for
          kunder og gjester, og hvordan de oppfatter virksomheten din.
        </p>
        <p class="text-section">
          Når du vil bygge din nettside er det også mye å ta hensyn til og en
          del å velge fra. Skal man bruke Wordpress, Webflow, Wix? Går det an å
          kode alt fra bunnen av, eller er det best å bruke ferdiglaget mal
          eller "templates"? Hva med server oppsett, søkemotoroptimalisering og
          analyseverktøy?
        </p>
        <p class="text-section">
          De alle fleste tenker kun på det de <b>tror</b> er mest relevant:
          Pris, leveringstid og utseende.
        </p>
        <p class="text-section">
          Alle disse er viktige og relevante egenskaper, men oppbyggingen av en
          optimalisert nettside handler om mye mer enn det. Det er viktig å ta
          hensyn til sikkerhet, synlighet, responsivitet, brukervennlighet,
          universell utforming, lover og regler.
        </p>
      </article>
    </div>
  </div>
</section>
<section
  class="info white"
  aria-label="Wordpress, Wix, Webflow eller Moderna Media digitalbyrå?"
  id="hjemmeside-2"
>
  <div class="info-container right">
    <div class="image-container">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Utvikling av hjemmesider for bedrift.jpg'
          )
        "
        alt=""
      />
    </div>
    <div class="text-container">
      <article
        aria-label="Wordpress, Wix, Webflow eller Moderna Media digitalbyrå?"
      >
        <div class="header">
          <p class="subtitle-gradient blue">Fordeler og ulemper</p>
          <h2 class="title-bold">
            Wordpress, Wix, Webflow eller Moderna Media Digitalbyrå?
          </h2>
        </div>
        <p class="text-section">
          For de alle fleste består en nettside kun av det de kan se på
          skjermen. De tenker på en fin layout, fornuftig fargebruk og innholdet
          som blir vist frem.
        </p>
        <p class="text-section">
          I virkeligheten er det mye mer som skjer bak scenen, usynlig for de
          som besøker en nettside men noe som påvirker i stor grad når det
          kommer til <b>prestasjon og synlighet på søkemotorer</b>.
        </p>
        <p class="text-section">
          Å lage en nettside ved å bruke, for eksempel, Wordpress kan være
          fornuftig for de som trenger noe som er laget på kort tid og med
          hovedmål å publisere tekstinnhold. Derfor brukes Wordpress veldig ofte
          til blogger, hvor det er stort behov for publisering av innhold og
          hyppige tekstoppdateringer.
        </p>
        <p class="text-section">
          <a
            class="inline-text-link"
            href="https://www.mindspun.com/blog/reasons-why-you-should-not-use-wordpress/"
            rel="nofollow noopener"
            target="blank"
            ><b>Ulempene er flere</b></a
          >. Du havner ofte opp med en nettside som er usynlig på Google, kan
          bli lettere utsatt for cyberangrep og som ikke vises like bra på
          mobilenheter eller nettbrett.
        </p>
        <p class="text-section">
          For en bedriftsnettside er det mye større behov for god synlighet,
          serversikkerhet og mobilvennlighet. En nettside kodet fra bunnen av
          gir deg full kontroll over:
        </p>
        <div class="list">
          <ul>
            <li>Serversikkerhet</li>
            <li>Design, struktur og oppsett</li>
            <li>Hastighet og brukervennlighet</li>
            <li>Synlighet og søkemotoroptimalisering</li>
          </ul>
        </div>
        <p class="text-section">
          Sammenlignet med sider som blir laget med ferdigstilte maler, som de
          designet på Wordpress eller Wix, krever en nettside kodet fra bunnen
          av mer spesifikk kompetanse, oppmerksomhet til detaljer og mye tid.
        </p>
        <p class="text-section">
          Men for hvert minutt og krone du investerer på å en hjemmeside kodet
          fra bunnen av får du tilbake i <b>kvalitet</b>,
          <b>funksjonalitet</b> og <b>forutsigbarhet</b>. Du ender opp med en
          stabil, sikker og robust nettside som presterer optimalt og som kan
          også ha en positiv effekt på din økonomisk bunnlinje.
        </p>
      </article>
      <div class="buttons">
        <a href="/pris" class="btn cta-2 shadow"><p>Be om pris</p></a>
      </div>
    </div>
  </div>
</section>
<!-- USP CARDS -->
<section
  class="usp-cards"
  aria-labelledby="usp-cards-title"
  id="bedrifthjemmeside"
>
  <div class="usp-cards-wrapper">
    <div class="titles">
      <p class="subtitle">Hvorfor du trenger</p>
      <h2 class="subtitle-gradient blue" id="usp-cards-title">
        Hjemmeside fra Moderna Media
      </h2>
    </div>
    <div class="cards">
      <div class="card">
        <div class="image">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Økt synlighet på nett med søkemotor optimalisering byrå.jpg'
              )
            "
            alt=""
          />
        </div>
        <div class="content">
          <h3>Økt synlighet</h3>
          <p>
            <a
              href="https://backlinko.com/google-ctr-stats"
              rel="nofollow noopener"
              target="blank"
              class="inline-text-link clean"
            >
              Det første organiske resultatet i Google får <b>31,7%</b> av alle
              klikkene
            </a>
            <a
              href="https://www.impactplus.com/blog/seo-statistics"
              rel="nofollow noopener"
              target="blank"
              class="inline-text-link clean"
            >
              og de fem første står for
              <b>67,60%</b> av dem.
            </a>
          </p>
          <p>
            <a
              href="https://backlinko.com/google-ctr-stats"
              rel="nofollow noopener"
              target="blank"
              class="inline-text-link clean"
              >Kun <b>0,78%</b> av Googlesøkerne klikker på noe fra side nr.
              2.</a
            >
          </p>
          <p>
            <a
              href="https://optinmonster.com/seo-ranking-factors/"
              rel="nofollow noopener"
              target="blank"
              class="inline-text-link clean"
            >
              Nettsidene vi koder fokuserer mye på de faktorene som har mest
              betydning for å ranger høyt på Google, som skriftlig innhold,
              mobilvennlighet, sidehastighet og sikkerhet.
            </a>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="image">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Digitalbyrå eksperter.jpg'
              )
            "
            alt=""
          />
        </div>
        <div class="content">
          <h3>Ekspertise</h3>
          <p>
            Med over <b>10-års erfaring</b> innen koding og design kan vi levere
            høykvalitets, sikre og robuste nettsider til en realistisk og
            fornuftig pris.
          </p>
          <p>
            Moderna Media bruker teknologi som SPA-rammeverk, Angular Universal
            og Server side rendering tilsvarende sider som Finn.no, Google og
            Facebook.
          </p>
          <p>
            Alt vi leverer er bygget fra bunnen av, med drømmekundene dine i
            fokus og vår <b>100% fornøydgaranti</b>.
          </p>
        </div>
      </div>
      <div class="card">
        <div class="image">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Glad dame som har fått effekt på bunnlinjen etter å ha digitalisert bedriften.jpg'
              )
            "
            alt=""
          />
        </div>
        <div class="content">
          <h3>Effekt på bunnlinjen</h3>
          <p>
            <a
              href="https://backlinko.com/hub/seo/visibility"
              rel="noopener nofollow"
              target="blank"
              class="inline-text-link clean"
            >
              Når du øker din synlighet på Google, øker du også trafikken til
              siden din.
            </a>
            Blir siden bygd med tanke på å konvertere besøkende til kunder, vil
            en av konsekvensene være <b>økt omsetning</b>.
          </p>
          <p>
            <a
              href="https://kampanje.com/tech-design/2022/01/--over-ti-milliarder-kroner-i-gdpr-boter-i-2021-og-amazon-og-meta-viser-vei/"
              rel="noopener nofollow"
              target="blank"
              class="inline-text-link clean"
            >
              En nettside som fokuserer på sikkerhet og tar hensyn til
              personopplysningsloven hjelper også deg å beskyte din bedrift mot
              data angrep og eventuelle økonomiske straff
            </a>
            knyttet til GDPR-brud.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Info icon -->
<section class="usp-info" aria-labelledby="usp-info-title">
  <div class="usp-info-wrapper">
    <div class="header">
      <img
        src="../../../../assets/Images/Icons/tjenester/services/customer.svg"
        alt=""
      />
      <h2 class="subtitle-gradient" id="usp-info-title">
        Hjelp din bedrift, hjelp dine kunder
      </h2>
    </div>
    <div class="content">
      <p>
        Din hjemmeside kan hjelpe deg å generere virksomhet, øke varemerkeverdi,
        promotere og finne kunder. Samtidig vil din hjemmeside være en sentral
        plattform for deg å sende meldinger til markedet. Du kan se på det som
        at din hjemmeside leverer din markedsmelding 24 timer i døgnet, 365
        dager i året! Med unntak av nettsider for e-handel, er de fleste
        nettsidene informasjonsorienterte og prøver å løse dine kunders
        problemer. Dersom du ikke har en hjemmeside vil det bli enklere for dine
        konkurrenter å hjelpe dine kunder.
      </p>
    </div>
  </div>
</section>
<!-- Tjenester -->
<section
  class="usp-card-list"
  aria-labelledby="usp-list-title"
  id="inkluderte-tjenester"
>
  <div class="usp-list-wrapper">
    <div class="header">
      <p class="subtitle">
        Design, SEO, vedlikehold, sikkerthet, GDPR kompabilitet, analytics. Alt
        inkludert.
      </p>
      <h2 class="subtitle-gradient blue" id="usp-list-title">
        Alle våre nettsider inkluderer disse gratis tjenestene
      </h2>
    </div>
    <div class="cards">
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/google analytics.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Google Analytics oppsett</h3>
        <p>
          Forstå hvilke kanaler kommer besøkende dine fra, hva som interesserer
          dem og hvordan de oppfører seg når de er på siden din.
        </p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/google my business.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Google My Business</h3>
        <p>Halvparten av Google-søkene er rettet mot lokale bedrifter.</p>
        <p>
          Når Google My Business er satt opp riktig øker du sjansen for å bli
          sett av nye kunder i ditt område.
        </p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/page-speed.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Optimalisering</h3>
        <p>
          En nettside som laster raskt er mer brukervennlig og rangerer høyere
          på Google. Dette oppnår vi med iterative gjennomganger av kode og
          komprimering av bilder og andre filer.
        </p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/serveroppsett.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Server oppsett</h3>
        <p>
          Vi ordner alt du trenger når det kommer til server oppsett og kobling
          av domenet ditt.
        </p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/ssl.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">TLS sikkerhet</h3>
        <p>SSL-sertifikat er inkludert i alle våre nettsider. Helt gratis.</p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/teknisk_seo.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Teknisk SEO</h3>
        <p>Bli sett av de som søker på det du tilbyr</p>
        <p>
          Søkermotoroptimalisering hjelper kundene dine å finne deg på Google
          før de finner konkurrentene dine.
        </p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/gdpr.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">GDPR kompabilitet</h3>
        <p>
          Cookie tillatelser, samtykke og trygg sporing av data. Vi setter opp
          din nettside for å være kompatibel med personopplysningloven, uten
          ekstra kostnader.
        </p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/search.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Google Search Console</h3>
        <p>
          Oppsett av google search console, slik at du kan finne hvordan dine
          kunder finner deg.
        </p>
      </div>
      <div class="card">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/tjenester/services/utvikling/lighthouse.svg'
            )
          "
          alt=""
          class="icon"
        />
        <h3 class="usp-card-title">Google tester</h3>
        <p>
          Vi utfører objektive tester på din nye hjemmeside slik at du kan se
          din prestasjon i forhold til dine konkurrenter.
        </p>
      </div>
    </div>
  </div>
  <a href="/pris" class="btn cta-2">Be om pris</a>
</section>
<!-- Vår prosess -->
<section class="process" aria-labelledby="process-title" id="neste-steg">
  <div class="wrapper">
    <div class="image">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Få ditt første utkast av ny hjemmeside innen kort tid.jpg'
          )
        "
        alt="Digitalbyrå som jobber med bedrift nettsider og design"
      />
    </div>
    <div class="content">
      <div class="content-wrapper">
        <div class="titles">
          <p class="subtitle">Dine neste steg</p>
          <h2 class="title" id="process-title">
            Ta kontakt i dag og få ditt første utkast innen 2 dager
          </h2>
          <p class="innhold">
            Start en samtale og fortell oss om ditt prosjekt. Vi trenger litt
            tid for å bli bedre kjent med deg, dine mål og det du trenger hjelp
            med, men etter kort tid får du ditt første utkast.
          </p>
        </div>
        <div class="liste">
          <div class="point">
            <div class="number">1</div>
            <div class="point-content">
              <p class="point-title">Kontakt</p>
              <p class="point-text">
                Book et uforpliktet videomøte eller chat med oss via mail. Målet
                i den fasen er å finne hvordan vi kan hjelpe deg og samtidig
                samle informasjon om bedriften din.
              </p>
            </div>
          </div>
          <div class="point">
            <div class="number">2</div>
            <div class="point-content">
              <p class="point-title">Planlegging</p>
              <p class="point-text">
                Med en gang vi har samlet den informasjonen vi trenger kan vi
                sette opp en plan og starte utviklingsprosessen.
              </p>
            </div>
          </div>
          <div class="point">
            <div class="number">3</div>
            <div class="point-content">
              <p class="point-title">Utvikling og leveranse</p>
              <p class="point-text">
                Gjennom hele utviklingsprosessen får du oppdateringer og
                mulighet til å godkjenne og verifisere vårt arbeid. Husk også
                vår 100% fornøyelsesgaranti, som fjerner all risiko for deg og
                sørger for at du får den nettsiden bedriften din trenger.
              </p>
            </div>
          </div>
        </div>
        <a href="/kontakt" class="btn cta-2">Book et uforpliktet møte</a>
      </div>
    </div>
  </div>
</section>
<!-- Prices -->
<section class="prices" aria-labelledby="prices-title" id="priser">
  <div class="wrapper">
    <div class="titles">
      <p class="subtitle">Invester i veksten av virksomheten din</p>
      <h2 class="subtitle-gradient blue" id="prices-title">Våre priser</h2>
    </div>
    <div class="prices-wrapper">
      <div class="price-component">
        <div class="img-wrapper">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Ensom barista som selger kaffe.webp'
              )
            "
            alt="Logo for liten bedrift som henger på skilt"
          />
          <h3 class="price-title">Enkeltside</h3>
          <h4 class="price-subtitle">
            Nettside for bedrifter i oppstartsfasen
          </h4>
          <p class="price-header-content">Bruk budsjettet ditt fornuftig.</p>
        </div>
        <div class="content-wrapper">
          <!-- <ul class="usp-list">
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">For bedrifter i oppstartsfasen</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google my business</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google analytics</p>
            </li>
          </ul> -->
          <div class="info">
            <p>
              En enkel men funksjonell nettside for bedrifter med litt mindre
              informasjon å formidle og enklere behov.
            </p>
            <p>
              Inkluderer oppsett av Google Analytics, Google My Business og
              Google Search Console.
            </p>
          </div>
          <div class="price">
            <p>Fra 10.000kr</p>
          </div>
        </div>
      </div>
      <div class="price-component">
        <div class="img-wrapper">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/Liten bedrift med bakeri som trenger digitale tjenester.jpg'
              )
            "
            alt="Liten bedrift som blant annet restaurant"
          />
          <h3 class="price-title">Flersiders</h3>
          <h4 class="price-subtitle">Nettside for små bedrifter</h4>
          <p class="price-header-content">
            Optimal for de aller fleste små bedrifter.
          </p>
        </div>
        <div class="content-wrapper">
          <!-- <ul class="usp-list">
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">For små bedrifter</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google my business</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google analytics</p>
            </li>
          </ul> -->
          <div class="info">
            <p>
              Få en optimert nettside med flere sider, navigasjon og som vokser
              med bedriften din.
            </p>
            <p>
              Inkluderer oppsett av Google Analytics, Google My Business og
              semrush integrering.
            </p>
          </div>
          <div class="price">
            <p>Fra 22.000kr</p>
          </div>
        </div>
      </div>
      <div class="price-component">
        <div class="img-wrapper">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/komplett hjemmeside for bedrift som driver restaurant.jpg'
              )
            "
            alt="Søkemotor optimalisering eller SEO for bedrifter"
          />
          <h3 class="price-title">Komplett pakke</h3>
          <h4 class="price-subtitle">Nettside for etablerte bedrifter</h4>
          <p class="price-header-content">
            Inkluderer alt de fleste bedrifter har behov for.
          </p>
        </div>
        <div class="content-wrapper">
          <!-- <ul class="usp-list">
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">For Etablerte bedrifter</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google my business</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google analytics</p>
            </li>
          </ul> -->
          <div class="info">
            <p>
              Flere sider, sømløst navigasjon, søkemotoroptimalisering, osv.
            </p>
            <p>
              Denne pakken bygger på SPA-teknologi og server-side rendering for
              optimal hastighet og prestasjon. Den blir også bygget med samme
              metodene som vår egen nettside.
            </p>
          </div>
          <div class="price">
            <p>Fra 50.000kr</p>
          </div>
        </div>
      </div>
      <div class="price-component">
        <div class="img-wrapper">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Tjenester/Tjeneste/Utvikling/Hjemmeside/kompleks hjemmeside med banebrytende teknologi i utvikling.jpg'
              )
            "
            alt="Søkemotor optimalisering eller SEO for bedrifter"
          />
          <h3 class="price-title">Kompleks pakke</h3>
          <h4 class="price-subtitle">
            Nettside for bedrifter med større behov
          </h4>
          <p class="price-header-content">
            En sofistikert digital løsning for bedrifter med spesifikke og
            komplekse krav.
          </p>
        </div>
        <div class="content-wrapper">
          <!-- <ul class="usp-list">
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Nettsider for bedrifter med større behov</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google my business</p>
            </li>
            <li class="usp">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Icons/home/check-mark.svg'
                  )
                "
                alt=""
              />
              <p class="usp-text">Google analytics</p>
            </li>
          </ul> -->
          <div class="info">
            <p>
              Vår mest eksklusiv pakke inkluderer alt fra betalingssystem og
              autorisering til integrerte databehandlingsløsninger med database
              og API.
            </p>
          </div>
          <div class="price">
            <p>Fra 115.000kr</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-about-your-next-project></app-about-your-next-project>
