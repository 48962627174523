<section class="not-found">
  <div class="img-wrapper">
    <img
      [src]="
        sanitizeImageUrl('../../../assets/Images/Error/Siden ikke funnet.webp')
      "
      alt="Moderna media side ikke funnet - kode 404"
      class="bg"
    />
    <div class="bg"></div>
  </div>
  <div class="wrapper">
    <h1>404</h1>
    <p class="scream">Fant ikke siden</p>
    <a class="btn cta-2" href="/" title="Hjemmeside">Gå hjem</a>
  </div>
</section>
