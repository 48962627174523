<div class="wrapper" itemscope itemtype="https://schema.org/Report">
  <article (window:resize)="onResize($event)">
    <div class="h1-wrapper">
      <h1 class="blogg-title" itemprop="name" itemprop="about">
        Hva tjente utviklere i 2021
      </h1>
    </div>
    <p class="super">
      <span class="super">732.711kr</span>
      <span class="top">I gjennomsnitt</span>
    </p>
    <section class="innholdsfortegnelse">
      <ul class="top">
        <li class="top-level">
          <button (click)="scrollToElement(forrord)">Forord</button>
          <ul class="second">
            <li class="second-level">
              <button (click)="scrollToElement(kode)">Kode</button>
            </li>
            <li class="second-level">
              <button (click)="scrollToElement(Sporsmalfraundersokelsen)">
                Spørsmål fra undersøkelsen
              </button>
            </li>
          </ul>
        </li>
        <li class="top-level">
          <button
            (click)="scrollToElement(DengjennomsnittligeNorskeutvikleren)"
          >
            Den gjennomsnittlige utvikleren
          </button>
          <ul class="second">
            <li class="second-level">
              <button (click)="scrollToElement(utvikleren)">utvikleren</button>
            </li>
          </ul>
        </li>
        <li class="top-level">
          <button (click)="scrollToElement(Utviklerlonni2021)">
            Utvikler lønn i 2021
          </button>
          <ul class="second">
            <li class="second-level">
              <button (click)="scrollToElement(Lonnsokningi2021fra2020)">
                Lønnsøkning i 2021 fra 2020
              </button>
            </li>
            <li class="second-level">
              <button (click)="scrollToElement(Utviklereslonnetterfag)">
                Hvilke utviklere tjener best
              </button>
            </li>
            <li class="second-level">
              <button (click)="scrollToElement(Startlonnforutviklerei2021)">
                Startlønn for utviklere i 2021
              </button>
            </li>
            <li class="second-level">
              <button (click)="scrollToElement(Utviklereivikentjenerbest)">
                Utviklere i Viken tjener best
              </button>
            </li>
            <li class="second-level">
              <button (click)="scrollToElement(Hvapavirkerutviklereslonn)">
                Hva påvirker utvikleres lønn
              </button>
            </li>
          </ul>
        </li>
        <li class="top-level">
          <button (click)="scrollToElement(Utviklereskarriereogprogressjon)">
            Utvikleres karriere og progressjon
          </button>
        </li>
        <li class="top-level">
          <button (click)="scrollToElement(Bonusordningoglonn)">
            Bonusordning og lønn
          </button>
        </li>
        <li class="top-level">
          <button (click)="scrollToElement(apenhetomlonnpaarbeidsplassen)">
            Åpenhet om lønn på arbeidsplassen
          </button>
        </li>
        <li class="top-level">
          <button
            (click)="scrollToElement(Utviklerlonnarbeidsplassogrekruttere)"
          >
            Utviklerlønn, arbeidsplass og rekruttere
          </button>
        </li>
        <li class="top-level">
          <button (click)="scrollToElement(konklusjon)">konklusjon</button>
        </li>
        <li class="top-level">
          <button (click)="scrollToElement(kode)">Kode</button>
        </li>
        <li class="top-level">
          <button (click)="scrollToElement(Tidligerartikkleromlonnfrakode24)">
            Tidliger artikkler om lønn fra kode24
          </button>
        </li>
      </ul>
    </section>
    <section class="forrord" id="forrord" #forrord itemprop="backstory">
      <div class="h2-wrapper">
        <h2>Forord</h2>
      </div>
      <p>
        Da kode24 delte svarene på sin lønnsundersøkelse for 2021
        <a
          rel="dofollow"
          href="https://www.kode24.no/artikkel/vi-deler-ut-lonna-til-1542-utviklere---lag-noe-goy/74748854"
        >
          "Vi deler ut lønna til 1.542 utviklere - lag noe gøy!"
        </a>
        bestemte jeg meg for å ta en “deep dive” i dataen.
      </p>
      <p>
        Før noe data ble bearbeidet hadde jeg 1541 tilgjengelige svar. Jeg
        bestemte meg deretter for å fjerne alle svar hvor oppgitt årslønn lønn
        var over 2 millioner kroner og under 300 000 kroner. Som resultat av
        dette fjernet jeg besvarelser med oppgitte lønninger på:
      </p>
      <p>
        2 200 000kr, 2 900 000kr, 7 600 000kr, 0kr, 7 900 000kr, 2 486 250kr,
        0kr, 0kr, 145 098kr, 0kr, 3 000 000kr og 0kr.
      </p>
      <p>
        Dette førte til at vi gikk fra 1541 svar til 1529 svar. Dette er
        fremdeles en økning på hele 130% fra fjorårets 661 respondenter.
      </p>
      <section #Sporsmalfraundersokelsen>
        <div class="h3-wrapper">
          <h3>Spørsmål fra undersøkelsen</h3>
        </div>
        <pre>
"Hva er din alder?"
"I hvilket fylke ligger jobben din?"
"Hva beskriver best din arbeidssituasjon?"
"Er du ansatt i det offentlige eller private næringsliv?"
"Hvor mange års relevant, formell utdannelse har du?"
"Hvor mange års relevant arbeidserfaring har du?"
"Hva jobber du mest med?"
"Har du en av disse begrepene i stillingstittelen din?"
"Hva er din grunnlønn? (årslønn før skatt, uten eventuelle bonuser eller overtidsbetaling)"
"Er du organisert? (Altså medlem av en forening som Tekna, El&it, Nito osv)"
"Pleier du å få mer utbetalt enn grunnlønn på grunn av overtidsbetaling?"
"Har du en bonusordning hos din nåværende arbeidsgiver?"
"Sist du gikk opp i lønn, hvordan skjedde det? (altså en helt ny lønn eller en lønnsjustering utover indeksregulering, sentrale oppgjør osv.)"
"Om du skal bytte jobb, hvilke goder kan være like viktig som lønn?"
"Skal jeg bytte jobb, skal jeg også opp i lønn.
"Hvor mye man tjener avhenger av hvor flink man er i jobben sin."
"Hvor mye man tjener avhenger av hvilken tittel man har."
"Hvor mye man tjener avhenger av hvor gammel man er" (Altså uavhengig av erfaring og fartstid i selskapet)"
"Utviklere er blant de som tjener mest på arbeidsplassen min."
"Jeg tror jeg hadde tjent mer nå om det ikke hadde vært for korona.\" (for eksempel på grunn av manglende lønnsjustering, mindre aktuelt å bytte jobb, osv)"
"Jeg synes man bør tjene mer om man jobber hjemmefra."
"Jeg synes man bør tjene mindre om man jobber hjemmefra."
"Jeg tror kjønn i seg selv spiller inn i hvor mye man tjener."
"Om noen spør, synes jeg det er greit å fortelle kolleger hva jeg tjener."
"Jeg synes det er greit å spørre kolleger hva de tjener."
"Vi burde ha mer åpenhet på en arbeidsplass om hva vi tjener."
      </pre
        >
      </section>
    </section>
    <section #DengjennomsnittligeNorskeutvikleren>
      <div class="h2-wrapper">
        <h2>Den gjennomsnittlige utvikleren</h2>
      </div>
      <img
        class="summary"
        src="../../../assets/Images/Blogg/UtviklerLønn/Den gjennomsnittlige utvikleren i norge.jpg"
        alt="Nøkkel tall om norsk utviklere basert på statistikk og grafer"
      />
      <section #utvikleren>
        <div class="h3-wrapper">
          <h3>Utvikleren</h3>
        </div>
        <p>
          Dataen sett i oppsummeringen/bildet ovenfor er basert på dataen
          presentert i artikkelen og er beregnet individuelt uten noen form for
          gruppering. Som vist har aldersgruppen 25-29 år flest respondenter,
          likevel er det flest respondenter med tittelen senior. Dette betyr
          ikke at gjennomsnittsalderen til seniorutviklere er mellom 25-29 år. I
          realiteten er det størst forekomst av seniorer i aldersgruppen 30-34
          år.
        </p>
        <p>
          Som man kan se av 1529 svar på undersøkelsen så er de fleste svarene
          fra utviklere i aldersgruppen: 25-29 år.
        </p>
        <div class="chart RespondantsByAge">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [showGridLines]="false"
            [results]="RespondantsByAge"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'alder'"
            [yAxisLabel]="'respondenter'"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">Antall respondenter per aldersgruppe</p>
        </div>
        <p>53% av respondentene svarte at de jobbet i Oslo.</p>
        <div class="chart RespondantsByLocation">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="schemeType"
            [results]="RespondantsByLocation"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="'fylke'"
            [showYAxisLabel]="'respondenter'"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">
            Antall respondenter basert på svar fra "I hvilket fylke ligger
            jobben din?"
          </p>
        </div>
        <hr />
        <div class="chart RespondantsByAgeByLocation">
          <ngx-charts-bar-vertical-2d
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="RespondantsByAgeByLocation"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'fylke'"
            [yAxisLabel]="'respondenter'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical-2d>
          <p class="subtext">
            Antall respondenter basert på svar fra "I hvilket fylke ligger
            jobben din?" gruppert i aldersgrupper
          </p>
        </div>
        <p>
          273 av respondentene er i aldersgruppen 25-29 år og bor i Oslo. Denne
          gruppen utgjør nesten 18% av alle respondenter på undersøkelsen.
        </p>
        <p>
          Fra grafen nedenfor kan man se de ulike fagene med rangert etter
          antall respondenter. De fleste utvikleren jobber med
          fullstack-utvikling, etterfulgt av backend og frontend.
        </p>
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="schemeType"
            [results]="RespondantsByWork"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'jobber med'"
            [yAxisLabel]="'respondenter'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">Antall respondenter basert hva de jobber med</p>
        </div>
        <!-- <hr />
        <div class="chart">
          <ngx-charts-bar-vertical
            [view]="view"
            [scheme]="colorSchemeSmall"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="schemeType"
            [results]="RespondantsByType"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'arbeidssituasjon'"
            [yAxisLabel]="'respondenter'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">
            Antall respondenter basert på beskrivelse av arbeidssituasjon
          </p>
        </div> -->
      </section>
    </section>
    <section #Utviklerlonni2021>
      <div class="h2-wrapper">
        <h2>Utviklerlønn i 2021</h2>
      </div>
      <img
        class="summary"
        src="../../../assets/Images/Blogg/UtviklerLønn/Gjennomsnitt-lønn-for-norsk-utvikler.jpg"
        alt="Gjennomsnitts lønn for norske utviklere"
      />
      <p>
        Median lønnen til norske utviklere var i 2021, 690 000kr.
        Gjennomsnittlønnen til norske utviklere var i 2021, 723.771kr. Dataen
        viser også at den gjennomsnittlige startlønnen til en utvikler er
        533.346kr.
      </p>
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="ValueSalaryByAge"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'alder'"
          [yAxisLabel]="'lønn'"
          [showGridLines]="false"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">Lønn basert på alder - 2021</p>
      </div>
      <section #Lonnsokningi2021fra2020>
        <div class="h3-wrapper">
          <h3>Lønnsøkning i 2021 fra 2020</h3>
        </div>
        <p>
          Årets data viser at utviklere hadde en økning i gjennomsnittslønn på
          nesten 30.000kr fra
          <a
            href="https://www.kode24.no/kodelokka/utvikleres-lonn---disse-tjener-mest-og-minst-i-norge/72061058"
            rel="dofollow"
          >
            2020 </a
          >. Dette gir en økning på 4.14% fra fjoråret, altså en reel vekst på
          -0.96% hvis man justerer for
          <a
            href="https://www.ssb.no/priser-og-prisindekser/konsumpriser/statistikk/konsumprisindeksen"
            rel="nofollow"
          >
            konsumprisindeksen (november 2021).
          </a>
        </p>
        <p>
          Generell gjennomsnittslønn mellom årene kan være vanskelig sammenligne
          ettersom det kan ha vært en større andel av aldersgruppen 24-29 år som
          svarte i år i motsetning til 2020. Heldigvis kan vi presentere
          gjennomsnittslønn basert på aldersgrupper mellom årene.
        </p>
        <div class="chart">
          <ngx-charts-bar-vertical-2d
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="ValueSalary21vs20"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'alder'"
            [yAxisLabel]="'lønn'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical-2d>
          <p class="subtext">Lønn basert på alder - 2020 mot 2021</p>
        </div>
        <p>
          Ut i fra grafen kan det se ut som det er noe feil i de to siset
          gruppene. Dette er grunnet grupperingene av alder. I 2020 gikk
          aldersgruppene opp til og med 45+, i motsettning til i år hvor vi har
          gruppene 45-50 og 50+. Generelt kan vi se at det har vært en
          lønnsøkning i løpet av 2021.
        </p>
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorSchemeSmall"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="ValueSalaryIncrease"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'alder'"
            [yAxisLabel]="'økning i kroner'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">
            Lønnsøkning i kr fra 2020 gruppert i aldersgrupper
          </p>
        </div>
        <p>
          på toppen kommer aldersgruppen 35-39 år ut med en lønnsøkning fra
          fjoråret på hele 44 819kr. Men når vi ser på prosentøkningen blir
          historien en helt annen.
        </p>
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorSchemeSmall"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="ValueSalaryIncreasePercent"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'alder'"
            [yAxisLabel]="'økning i prosent'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">
            Lønnsøkning i prosent fra 2020 gruppert i aldersgrupper
          </p>
        </div>
        <p>
          Utviklere i aldersgruppen 20-24 år kommer ut på topp med en økning på
          6.5% fra fjoråret.
        </p>
      </section>
      <section #Utviklereslonnetterfag>
        <div class="h3-wrapper">
          <h3>Hvilke utviklere tjener best</h3>
        </div>
        <p>
          I årets undersøkelse ble utviklere spurt
          <span class="italic">"Hva jobber du mest med?"</span>. Hvis vi
          sorterer lønn basert på fag får vi denne grafen
        </p>
        <div class="chart">
          <ngx-charts-bar-horizontal
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="ValueSalaryByWork"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'lønn'"
            [yAxisLabel]="'fag'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-horizontal>
          <p class="subtext">
            Gjennomsnittslønn basert på "hva utvikleren jobber mest med"
          </p>
        </div>
        <div class="summary-box blogg-title">
          <p class="no_padding blogg-title">
            De 3 fagområdene med høyest gjennomsnittslønn:
          </p>
          <ul class="list-decoration-disc">
            <li>
              <p>Arkitekt - 960.500kr</p>
            </li>
            <li>
              <p>Ledelse/Administrativt - 901.004kr</p>
            </li>
            <li>
              <p>Databaser - 839.722kr</p>
            </li>
          </ul>
        </div>
        <div class="summary-box">
          <p class="no_padding blogg-title">
            De 3 fagområdene med lavest gjennomsnittslønn:
          </p>
          <ul class="list-decoration-disc">
            <li>
              <p>UX / Design - 660.229kr</p>
            </li>
            <li>
              <p>Front-end - 669.706kr</p>
            </li>
            <li>
              <p>Sikkerhet - 675.265kr</p>
            </li>
          </ul>
        </div>
      </section>
      <section #Startlonnforutviklerei2021>
        <div class="h3-wrapper">
          <h3>Startlønn for utviklere i 2021</h3>
        </div>
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="ValueSalaryByYoe"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [xAxisLabel]="'år med erfaring'"
            [yAxisLabel]="'lønn'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">lønn basert på antall år med erfaring</p>
          <p class="subtext">respondenter i parantes</p>
        </div>
        <p>
          Fra denne grafen finner vi at gjennomsnittslønnen til 82 respondenter
          med 0 års erfaring er 533.346kr! Man finner også at de med 1 års
          erfaring har gjennomsnittlig i overkant av 20.000kr mer i lønn enn de
          uten erfaring.
        </p>
        <p>
          På grafen kan det se ut som man får betraktelig mer lønn det året man
          treffer 29 år med erfaring. Men realiteten er mest sannsynlig at de 2
          respondentene er et avvik fra normalen. Av denne grunnen er det lagt
          til antall respondenter i parantes.
        </p>
      </section>
      <section #Utviklereivikentjenerbest>
        <div class="h3-wrapper">
          <h3>Utviklere i Viken tjener best</h3>
        </div>
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="schemeType"
            [results]="ValueSalaryByCounty"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'arbeidssted'"
            [yAxisLabel]="'lønn'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">lønn basert på fylke man jobber i</p>
          <p class="subtext">respondenter i parantes</p>
        </div>
        <p>
          Overraskende nok kommer Viken på topp med gjennomsnittslønn på
          757.813kr over Oslo som kommer på andreplass med en gjennomsnittslønn
          på 748.469kr.
        </p>
      </section>
      <section #Hvapavirkerutviklereslonn>
        <div class="h3-wrapper">
          <h3>Hva påvirker utvikleres lønn</h3>
        </div>
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorSchemeSmall"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="schemeType"
            [results]="RespondantsByTitleImportance"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'svar'"
            [yAxisLabel]="'respondenter'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">
            respondenter basert på spørsmålet "Hvor mye man tjener avhenger av
            hvilken tittel man har"
          </p>
        </div>
        <hr />
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorSchemeSmall"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="schemeType"
            [results]="RespondantsByAgeImportance"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'respondenter'"
            [yAxisLabel]="'svar'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">
            respondenter basert på om de mener alder påvirker lønn
          </p>
        </div>
        <p>
          Majoriteten av respondentene i undersøkelsen mener at alder ikke
          påvirker lønn, noe som motbevises tidligere i statistikken hvor de
          laveste aldersgruppene har minst lønn. Eller har de bare lavest lønn
          fordi de har minst erfaring og kortest fartstid?
        </p>
        <div class="chart">
          <ngx-charts-bar-vertical-2d
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="ValueSalaryByYoeAge"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'aldersgruppe'"
            [yAxisLabel]="'Gjennomsnittslønn'"
            [showGridLines]="false"
            [groupPadding]="0"
            [barPadding]="0"
          >
          </ngx-charts-bar-vertical-2d>
          <p class="subtext">
            lønn basert på antall år med erfaring, gruppert i aldersgrupper
          </p>
          <p class="subtext">respondenter i parantes</p>
        </div>
        <p>
          Her blir dataen presentert i gjennomsnittslønn basert på alder
          gruppert i år med erfaring. For at grafen ikke skulle ha for store
          avvik ble gruppene med under 3 respondenter fjernet. Dataen kan også
          presenteres slik at lønn blir byttet ut med respondenter for å finne
          gjennomsnittlig erfaring basert på alder.
        </p>

        <div class="chart">
          <ngx-charts-bar-vertical-2d
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorScheme"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="'linear'"
            [results]="RespondantsYoeByAge"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'respondenter'"
            [yAxisLabel]="'aldersgruppe'"
            [showGridLines]="false"
            [groupPadding]="0"
          >
          </ngx-charts-bar-vertical-2d>
          <p class="subtext">
            respondenter basert på antall år med erfaring, gruppert i
            aldersgrupper
          </p>
        </div>
        <p>
          I aldersgruppen 20-24 år så har de fleste 1 års erfaring. I
          alderskruppen 45-50 år er det størst forekomst av respondenter med 23
          års erfaring. Dette kan indikere at alder henger sammen med antall år
          erfaring, noe som igjen kan peke på ferdighetsnivå og lønn.
        </p>
        <div class="chart">
          <ngx-charts-bar-vertical
            *ngIf="DeveloperSalaryReady"
            [view]="view"
            [scheme]="colorSchemeSmall"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="false"
            [schemeType]="schemeType"
            [results]="RespondantsBySkillImportance"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="'respondenter'"
            [yAxisLabel]="'svar'"
            [showGridLines]="false"
          >
          </ngx-charts-bar-vertical>
          <p class="subtext">
            respondenter basert på om de mener ferdighetsnivå påvirker lønn
          </p>
        </div>
      </section>
    </section>
    <section #Utviklereskarriereogprogressjon>
      <div class="h2-wrapper">
        <h2>Utvikleres karriere og progressjon</h2>
      </div>
      <p>
        En utvikler starter ofte yrkeskarrieren med "junior"-tittelen. Dette kan
        vi se ut i fra grafen nedenfor hvor de aller fleste mellom 0-2 års
        erfaring har junior i tittelen. Deretter vil en utvikler vanligvis gå
        videre til en såkalt “mid-level utvikler” som også går under tittelen
        “utvikler“. Dessverre kommer ikke dette godt nok frem i undersøkelsen og
        det kan være vanskelig å finne svar på ettersom flere arbeidsgivere,
        spesielt konsulentvirksomheter går bort fra juniortittelen og rett på
        “utvikler“. Neste steg i karrierestigen er normalt tittelen
        "senior-utvikler" hvor det forekommer flest respondenter med 5 års
        erfaring. Her holder grafen seg stødig til og med 15 års erfaring før
        den avtar og utviklerne fordeles blant andre tittler. Som vist tidligere
        har arkitektene best betalt. Dessverre blir dataen mer komplisert å
        analysere ettersom grupperingen tynner ut respondentene i gruppene, men
        vi kan finne arkitekter med alt fra 1 års erfaring til og med 23 års
        erfaring.
      </p>

      <div class="chart">
        <ngx-charts-bar-vertical-2d
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="'linear'"
          [results]="RespondantsYoeByTitle"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'respondenter'"
          [yAxisLabel]="'tittel'"
          [showGridLines]="false"
          [groupPadding]="1"
        >
        </ngx-charts-bar-vertical-2d>
        <p class="subtext">
          respondenter basert på antall år med erfaring gruppert i tittler.
        </p>
      </div>
      <p>
        Grafen for alder etter tittel er noe mer ryddig ettersom aldersspennene
        er grupperte. For junior-utviklere ligger gjennomsnittsalderen mellom 25
        og 29 år. Junior-utviklere har høyest forekomst av aldersgruppen 20-24
        år. I aldersgruppen 20-24 år finner vi et fåtall av senior utviklere,
        men i aldersgruppen 25-29 år er det hele 72 respondenter. Senior
        utviklerne topper ut og vi finner at gjennomsnittsalderen til en senior
        utvikler er mellom 30 og 34 år. Tidligere ble det nevnt at dataen for
        arkitekter var vanskelig å tolke da den var gruppert etter år med
        erfaring. Denne dataen kommer tydeligere frem når det er gruppert etter
        alder. Det er kanskje ikke overraskende at vi ser en høyest forekomst av
        aldersgruppen 40 - 50 år hos arkitektene. Det er heller ingen
        respondenter under 30 år med tittelen "arkitekt" i denne undersøkelsen.
      </p>
      <div class="chart">
        <ngx-charts-bar-vertical-2d
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="'linear'"
          [results]="RespondantsAgeByTitle"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'tittel'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
          [groupPadding]="1"
        >
        </ngx-charts-bar-vertical-2d>
        <p class="subtext">respondenter basert på alder gruppert i tittler.</p>
      </div>
    </section>
    <section #Bonusordningoglonn>
      <div class="h2-wrapper">
        <h2>Bonusordning og lønn</h2>
      </div>
      <p>
        I denne undersøkelsen ble spørsmålet om lønn formulert slik:
        <span class="italic">
          “Hva er din grunnlønn? (årslønn før skatt, uten eventuelle bonuser
          eller overtidsbetaling)“ </span
        >, altså uten bonus.
      </p>
      <div class="chart">
        <ngx-charts-bar-vertical-2d
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="'linear'"
          [results]="RespondantsByAgeByBonus"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'alder'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
          [groupPadding]="15"
        >
        </ngx-charts-bar-vertical-2d>
        <p class="subtext">
          respondenter basert på om de har bonus gruppert i aldersgrupper.
        </p>
      </div>
      <p>
        Nesten 50% av respondentene i aldersgruppen 25-29 år har en
        bonusordning, men i aldersgruppen 30-34 år er det helt klart flest uten
        bonus.
      </p>
      <p>Vi kan også se hvilken sektor de med bonus jobber i.</p>
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorSchemeSmall"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="RespondantsBySector"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'sektor'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
          [barPadding]="1"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">respondenter basert på hvilken sektor de jobber.</p>
      </div>
      <hr />
      <p>
        Nå som data om bonus etter sektor har blitt presentert kan vi se videre
        på lønn etter bonus
      </p>
      <div class="chart">
        <ngx-charts-bar-horizontal-2d
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="'linear'"
          [results]="ValueSalaryByBonusByAge"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'gjennomsnittslønn'"
          [yAxisLabel]="'alder'"
          [showGridLines]="false"
          [groupPadding]="1"
        >
        </ngx-charts-bar-horizontal-2d>
        <p class="subtext">
          gjennomsnittslønn basert på om de har bonus gruppert i aldersgrupper
        </p>
      </div>
      <p>
        Dataen viser tydelig at det er flere aldersgrupper med bonusordning som
        samtidig har høyest gjennomsnitslønn.
      </p>
    </section>
    <section #apenhetomlonnpaarbeidsplassen>
      <div class="h2-wrapper">
        <h2>Åpenhet om lønn på arbeidsplassen</h2>
      </div>
      <p>
        Det var også enkelte spørsmål i undersøkelsen angående åpenhet rundt
        lønn:
      </p>
      <ul class="list-decoration-disc">
        <li>
          <p>
            Om noen spør, synes jeg det er greit å fortelle kolleger hva jeg
            tjener.
          </p>
        </li>
        <li><p>Jeg synes det er greit å spørre kolleger hva de tjener.</p></li>
        <li>
          <p>Vi burde ha mer åpenhet på en arbeidsplass om hva vi tjener.</p>
        </li>
      </ul>
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorSchemeSmall"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="RespondantsShareSalary"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'svar'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">
          Respondenter basert på "Om noen spør, synes jeg det er greit å
          fortelle kolleger hva jeg tjener"
        </p>
      </div>
      <hr />
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorSchemeSmall"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="RespondantsAskSalary"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'svar'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">
          Respondenter basert på "Jeg synes det er greit å spørre kolleger hva
          de tjener"
        </p>
      </div>
      <hr />
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorSchemeSmall"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="RespondantsOpenSalary"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'svar'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">
          Respondenter basert på "Vi burde ha mer åpenhet på en arbeidsplass om
          hva vi tjener"
        </p>
      </div>
      <hr />
      <p>
        Med denne dataen kan vi også se på utviklere som gjerne spør om lønn,
        men ikke ønsker å dele.
      </p>
      <div class="chart">
        <ngx-charts-bar-vertical-2d
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="'linear'"
          [results]="RespondantsAskSalaryByShareSalary"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'svar'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
          [groupPadding]="1"
        >
        </ngx-charts-bar-vertical-2d>
        <p class="subtext">
          Respondenter basert på om de gjerne spør om lønn, gruppert basert på
          om de ønsker å dele lønn dersom de blir spurt.
        </p>
      </div>
      <p>
        Her kan vi se grupperinger basert på om det er greit å spørre om lønnen
        til andre kollegaer. Deretter blir det delt inn i grupperinger om det er
        greit å svare på spørsmål om lønn. Her kan vi finne 4 personer som
        gjerne spør om lønn, men ikke ønsker å dele lønnen sin med kollegaer.
        Over halvparten av respondentene som ikke ønsker å spørre om lønn er
        fremdeles villig til å dele lønnen sin.
      </p>
    </section>
    <section #Utviklerlonnarbeidsplassogrekruttere>
      <div class="h2-wrapper">
        <h2>Utviklerlønn, arbeidsplass og rekruttere</h2>
      </div>
      <p>
        Nå som 2021 nærmer seg over vet vi at
        <a
          href="https://www.latimes.com/business/story/2021-07-31/employers-bow-down-to-tech-workers-in-hottest-job-market"
          rel="nofollow"
        >
          markededet har vært "hot"
        </a>
        for utviklere verden over og innboksen blir fylt opp av rekruttere på
        LinkedIn. Dersom du er en rekrutter eller bare en nysgjerrig utvikler er
        denne seksjonen for deg.
      </p>
      <p>Er utviklere stort sett fornøyd med lønnen sin?</p>
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorSchemeSmall"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="RespondantsSalaryHappy"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'svar'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">
          Respondenter basert på svar fra "Alt i alt - er du fornøyd med din
          egen lønn?"
        </p>
      </div>
      <p>
        Stort sett er utviklere fornøyd med lønnen sin. Samtidig svarer 32% at
        de ikke er det. et tips til rekruttere kan være å inkludere lønnen for
        stillingsutlysningen når de kontakter utviklere med ledige stillinger.
      </p>
      <p>
        De fleste er fornøyd med lønnen sin, men ønsker likevel å gå opp i lønn
        dersom de skal bytte arbeidsplass.
      </p>
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="RespondantsSalaryIncreaseByJobChange"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'svar'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">
          Respondenter basert på om svar fra "Skal jeg bytte jobb, skal jeg også
          opp i lønn"
        </p>
      </div>
      <hr />
      <p>
        Norske utviklere har svart på "Om du skal bytte jobb, hvilke goder kan
        være like viktig som lønn?"
      </p>
      <div class="chart">
        <ngx-charts-bar-vertical
          *ngIf="DeveloperSalaryReady"
          [view]="view"
          [scheme]="colorScheme"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="false"
          [schemeType]="schemeType"
          [results]="RespondantsGoods"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="'svar'"
          [yAxisLabel]="'respondenter'"
          [showGridLines]="false"
        >
        </ngx-charts-bar-vertical>
        <p class="subtext">
          Respondenter basert på om svar fra "Om du skal bytte jobb, hvilke
          goder kan være like viktig som lønn?"
        </p>
      </div>
      <p>
        Grafen viser ønsker om sosiale sammenkomster, frynsegods, fagilig
        utvikling, men grafen topper ut med fleksible arbeidstimer og
        hjemmekontor.
      </p>
      <p>
        Enkelte har også nevnt andre goder som kan være viktig i fritekstfeltet
        på undersøkelsen. blant annet ble det nevnt: “Øh(1)“, “Impact!(1)“ og
        “Som google(1)“.
      </p>
      <p>
        Dessverre kommer ikke all data frem i grafen da det var mulig å skrive
        hva man ønsket i fritekst. Det er også fjernet besvarelser med under 5
        respondenter fra grafen. Likevel finnes det enkelte gjengangere med
        forskjellige skrivemåter:
      </p>
      <ul class="list-decoration-disc">
        <li><p>Jobb med mening</p></li>
        <li><p>Dyktige kollegaer / flinke folk</p></li>
        <li><p>Mulighet for egenlæring</p></li>
        <li><p>Aksjer / medeierskap</p></li>
        <li><p>Godt miljø</p></li>
        <li><p>Forsikring</p></li>
        <li><p>Pensjon</p></li>
        <li><p>Mobil / internett</p></li>
      </ul>
    </section>
    <section #konklusjon>
      <div class="h2-wrapper">
        <h2>Konklusjon</h2>
      </div>
      <p>
        Gjennom flere grafer og 40.092 datapunkter har vi sett at utviklere
        tjener generelt godt i Norge, spesielt arkitektene!
      </p>
      <p>
        Dersom det er enkelte grafer du savner kan du laste ned git repository
        som ble lenket lengre opp i artikkelen eller sende meg en mail på
        kontakt@modernamedia.no
      </p>
      <p>
        Forhåpentligvis vil kode24 også gi ut neste års data slik at vi får
        muligheten til å grave litt dypere hvor det er behov. Det har vært en
        del snakk om mangfold det siste året og det hadde vært interessant å
        sett kjønn og etnisitet som en del av datagrunnlaget.
      </p>
    </section>
    <section #kode>
      <div class="h2-wrapper">
        <h2>Kode</h2>
      </div>
      <p>
        Jeg har valgt å legge ut resultatene på
        <a href="modernamedia.no" rel="dofollow">modernamedia</a> (WIP) ettersom
        jeg allerede har en deploy pipeline og et domene tilgjengelig på dette
        prosjektet. Modernamedia.no er bygget på Angular universal og det ble da
        naturlig å bruke typescript for å kalkulere dataen til grafene. For å
        vise grafene brukte jeg
        <a rel="nofollow" href="https://swimlane.gitbook.io/ngx-charts/"
          >ngx-charts</a
        >
        noe som i ettertid har vist seg å være noe begrenset.
      </p>
      <p>
        Dersom du ønsker å se på koden har ModernaMedia.no åpen kildekode:
        <a href="https://github.com/StanlyLife/ModernaMedia" rel="nofollow">
          GitHub
        </a>
        , men for å gjøre det enkelt har jeg også laget et eget prosjekt med
        html og JavaScript, som dere selv kan laste ned her
        <a
          rel="nofollow"
          href="https://github.com/StanlyLife/DeveloperSalaryCalculator"
        >
          GitHub
        </a>
      </p>
      <p class="italic">
        Dersom du liker noen av prosjektene så gjerne følg meg på github
        <a rel="nofollow" href="https://github.com/StanlyLife">github</a>
      </p>
    </section>
    <section #Tidligerartikkleromlonnfrakode24>
      <div class="h2-wrapper">
        <h2>Tidliger artikkler om lønn fra kode24</h2>
      </div>
      <div class="summary-box blogg-title">
        <ul class="list-decoration-disc">
          <li>
            <a
              href="https://www.kode24.no/kodelokka/disse-utviklerne-tjener-best-i-norge/70934046"
              >2019 - Disse utviklerne tjener best i Norge</a
            >
          </li>
          <li>
            <a
              href="https://www.kode24.no/kodelokka/utvikleres-lonn---disse-tjener-mest-og-minst-i-norge/72061058"
              >2020 - Utvikleres lønn - disse tjener mest og minst i Norge</a
            >
          </li>
          <li>
            <a
              href="https://www.kode24.no/artikkel/vi-deler-ut-lonna-til-1542-utviklere---lag-noe-goy/74748854"
              >2021 - Vi deler ut lønna til 1.542 utviklere - lag noe gøy!</a
            >
          </li>
        </ul>
      </div>
      <hr />
    </section>
  </article>
  <app-author></app-author>
</div>
