<section
  class="usp-section"
  aria-label="Gode grunner til å modernisere med Moderna Media digitalbyrå"
>
  <div class="wrapper">
    <header>
      <div class="subtitle">Gode grunner til å Modernisere</div>
      <h2 class="title">Derfor bør du velge Moderna Media</h2>
      <app-testimonials></app-testimonials>

      <p class="section-info">
        Tjenestene vi tilbyr skal være en investering, ikke en kostnad. Digital
        markedsføring handler om mer enn kun synlighet eller betalt reklamering:
        Det handler om å skape spesifikke og målbare resultater.
      </p>
    </header>
    <div class="usp-container">
      <!-- usp -->
      <div class="usp">
        <div class="bg">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Home/usp/350/Fornøyd kunde som gir tommel opp.webp'
              )
            "
            alt="Glad kunde som er veldig fornøyd og gir tommelen opp"
          />
          <div class="shadow"></div>
        </div>
        <h3 class="usp-title">100% fornøyd garanti</h3>
        <p class="usp-content">
          Som vår kunde blir du garantert synlighet og måloppnåelse. Ellers får
          du pengene tilbake.
        </p>
      </div>
      <!-- usp -->
      <div class="usp">
        <div class="bg">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Home/usp/350/et team av eksperter som jobber med digitalisering i et markedsføringsbyrå.webp'
              )
            "
            alt="Eksperter innenfor digital markedsføring i et team av eksperter som jobber med digitalisering i et markedsføringsbyrå"
          />
          <div class="shadow"></div>
        </div>
        <h3 class="usp-title">
          Få digitale løsninger baserte på objektiv data og kompetanse
        </h3>
        <p class="usp-content">
          Våre moderne og løsningsorienterte metoder hjelper deg å oppnå
          suksess, synlighet og vekst på nett.
        </p>
      </div>
      <!-- usp -->
      <div class="usp" title="Målrettet vekst med tydelig resultat">
        <div class="bg">
          <img
            [src]="
              sanitizeImageUrl(
                '../../../../assets/Images/Home/usp/350/Arbeidskvinne som jobber med resultat og vekst.webp'
              )
            "
            alt="Digitalbyrå i oslo som gir resultater"
          />
          <div class="shadow"></div>
        </div>
        <h3 class="usp-title">Målrettet vekst med tydelig resultat</h3>
        <p class="usp-content">
          Med tjenester som fokuserer på det som skaper resultater gjør vi det
          enkelt å oppnå dine mål.
        </p>
      </div>
    </div>
  </div>
</section>
