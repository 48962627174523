<div class="author">
  <div class="img">
    <img
      src="../../../assets/Images/people/Stian håve.jpg"
      alt="Skribent av artikkel"
    />
  </div>
  <div class="info">
    <p class="name">Stian Håve</p>
    <p class="author-title">Full-stack utvikler</p>
    <a class="contact" href="mailto:kontakt@modernamedia.no"
      >kontakt@modernamedia.no</a
    >
  </div>
</div>
