<section class="about-your-next-project">
  <div class="bg">
    <picture>
      <source
        media="(min-width:1280px)"
        [srcset]="
          sanitizeImageUrl(
            '../../../assets/Images/Components/about-your-next-project/1920/Moderna Media Digitalybyrå Background pattern.webp'
          )
        "
      />
      <source
        media="(min-width:640px)"
        [srcset]="
          sanitizeImageUrl(
            '../../../assets/Images/Components/about-your-next-project/1280/Moderna Media Digitalybyrå Background pattern.webp'
          )
        "
      />
      <source
        media="(min-width:400px)"
        [srcset]="
          sanitizeImageUrl(
            '../../../assets/Images/Components/about-your-next-project/640/Moderna Media Digitalybyrå Background pattern.webp'
          )
        "
      />
      <source
        media="(min-width:0px)"
        [srcset]="
          sanitizeImageUrl(
            '../../../assets/Images/Components/about-your-next-project/400/Moderna Media Digitalybyrå Background pattern.webp'
          )
        "
      />
      <img
        [src]="
          sanitizeImageUrl(
            '../../../assets/Images/Components/about-your-next-project/1920/Moderna Media Digitalybyrå Background pattern.webp'
          )
        "
        alt=""
        role="presentation"
      />
    </picture>
  </div>
  <div class="wrapper">
    <div class="text">
      <p class="lets-talk">Ikke vent</p>
      <p class="om-ditt">Med ditt</p>
      <p class="scream last">neste prosjekt.</p>
    </div>
    <div class="get-in-touch">
      <a class="btn cta-2" href="/kontakt"><p>Book et uforpliktet møte</p></a>
    </div>
  </div>
</section>
