<header [hidden]="hidden">
  <div class="wrapper">
    <a
      class="logo-anchor"
      routerLink="/"
      title="Hjemmeside"
      aria-label="Hjemmeside"
    >
      <div class="logo">
        <img
          src="{{
            imageCdn == '' ? '' : imageCdn
          }}../../assets/Images/LogoV2/modernamedia-small-small.png"
          alt="Moderna media logo"
        />
      </div>
    </a>
    <nav role="navigation" id="navbar">
      <a
        routerLink="/tjenester/bedrift/utvikling"
        [routerLinkActive]="[true]"
        class="nav-link {{ textClass }}"
      >
        <div class="slide"></div>
        <p>Utvikling</p>
      </a>
      <a
        routerLink="/tjenester/bedrift/design"
        [routerLinkActive]="[true]"
        class="nav-link {{ textClass }}"
      >
        <div class="slide"></div>
        <p>Design</p>
      </a>
      <a
        routerLink="/tjenester/bedrift/seo/søkemotoroptimalisering"
        [routerLinkActive]="[true]"
        class="nav-link {{ textClass }}"
      >
        <div class="slide"></div>
        <p>SEO</p>
      </a>
    </nav>
    <div class="buttons">
      <a
        class="btn cta-2 contact"
        (click)="ContactUs($event)"
        routerLink="/kontakt"
        title="skroll til kontakt monderna media"
      >
        <p>Kontakt</p>
      </a>
      <button class="btn cta-2 menu" (click)="Menu($event)"><p>Meny</p></button>
    </div>
  </div>
</header>
