<section class="form-section" aria-label="kontakt skjema" id="kontakt">
  <div class="form-container">
    <header>
      <p class="subtitle">Kontakt oss, 100% uforpliktet!</p>
      <h2 class="title">Hvordan kan vi hjelpe deg?</h2>
      <p class="form-section-content">
        Vi vil svare på meldingen din så raskt som mulig. Dersom du skriver inn
        telefonnummeret ditt vil vi sende deg en bekreftelse på mottatt melding.
      </p>
    </header>
    <div class="kontakt-info">
      <a href="mailto:kontakt@modernamedia.no" class="mail">
        <img
          [src]="
            sanitizeImageUrl('../../../../assets/Images/Icons/forms/mail.svg')
          "
          alt=""
          role="presentation"
          class="icon"
        />
        Mail: <span> kontakt@modernamedia.no</span></a
      >
      <a href="tel:90265326" class="mail">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/forms/smartphone.svg'
            )
          "
          alt=""
          role="presentation"
          class="icon"
        />
        Telefon: <span> 902 65 326</span></a
      >
    </div>
    <p class="status-message">
      Skjema status:
      <span
        class="status"
        [ngClass]="{
          idle: !contactForm.valid && !contactForm.touched,
          error: formError,
          ready: contactForm.valid && contactForm.touched && !sent,
          sent: sent && result,
          failed: sent && !result
        }"
      >
        <img
          [src]="
            sanitizeImageUrl('../../../../assets/Images/Icons/forms/more.svg')
          "
          alt=""
          *ngIf="!contactForm.valid && !formError && !sent && !result"
        />
        <img
          [src]="
            sanitizeImageUrl('../../../../assets/Images/Icons/forms/cancel.svg')
          "
          alt=""
          *ngIf="formError || (sent && !result)"
        />
        <img
          [src]="
            sanitizeImageUrl('../../../../assets/Images/Icons/forms/check.svg')
          "
          alt=""
          *ngIf="
            (contactForm.valid && contactForm.touched && sent && result) ||
            (contactForm.valid && contactForm.touched && !sent && !result)
          "
        />
        <img
          [src]="
            sanitizeImageUrl('../../../../assets/Images/Icons/forms/mail.svg')
          "
          alt=""
          *ngIf="sent && result"
        />
        {{
          formError
            ? "feil! Fyll ut tekstfelt i rød"
            : contactForm.valid && contactForm.touched && !sent
            ? "klar til å sende melding"
            : sent && result
            ? "melding sendt!"
            : sent && !result
            ? "melding feilet, vennligst send mail til kontakt@modernamedia.no"
            : "fyll ut tekstfelt markert med (*)"
        }}
      </span>
    </p>
    <form
      action="post"
      class="kontakt-form"
      [formGroup]="contactForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-group">
        <img
          [src]="
            sanitizeImageUrl('../../../../assets/Images/Icons/forms/user.svg')
          "
          alt=""
          role="presentation"
          class="icon"
        />
        <input
          type="text"
          placeholder="Fullt navn"
          formControlName="name"
          aria-label="Fullt navn"
          type="name"
          autocomplete="name"
          [ngClass]="{
            invalid:
              !contactForm.controls['name'].valid &&
              contactForm.controls['name'].touched,
            sent: result && sent,
            error: !result && sent
          }"
        />
      </div>
      <div class="form-group">
        <img
          [src]="
            sanitizeImageUrl('../../../../assets/Images/Icons/forms/mail.svg')
          "
          alt=""
          role="presentation"
          class="icon"
        />
        <input
          type="text"
          placeholder="E-post adresse *"
          formControlName="email"
          aria-label="E-post adresse, påkrevd"
          type="email"
          autocomplete="email"
          [ngClass]="{
            invalid:
              !contactForm.controls['email'].valid &&
              contactForm.controls['email'].touched,
            sent: result && sent,
            error: !result && sent
          }"
        />
      </div>
      <div class="form-group">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/forms/smartphone.svg'
            )
          "
          alt=""
          role="presentation"
          class="icon"
        />
        <input
          type="tel"
          placeholder="Telefonnummer"
          formControlName="phone"
          max="99999999"
          aria-label="telefonnummer"
          autocomplete="tel"
          minlength="8"
          maxlength="8"
          type="tel"
          [ngClass]="{
            invalid:
              !contactForm.controls['phone'].valid &&
              contactForm.controls['phone'].touched,
            sent: result && sent,
            error: !result && sent
          }"
        />
      </div>
      <div class="form-group">
        <img
          [src]="
            sanitizeImageUrl(
              '../../../../assets/Images/Icons/forms/suitcase.svg'
            )
          "
          alt=""
          role="presentation"
          class="icon"
        />
        <input
          type="text"
          placeholder="Tilhørende bedrift"
          formControlName="business"
          aria-label="tilhørende bedrift"
          type="text"
          autocomplete="off"
          [ngClass]="{
            invalid:
              !contactForm.controls['business'].valid &&
              contactForm.controls['business'].touched,
            sent: result && sent,
            error: !result && sent
          }"
        />
      </div>
      <div class="form-group">
        <textarea
          type="text"
          placeholder="Melding *"
          aria-label="melding"
          formControlName="body"
          aria-label="meldings tekst, påkrevd"
          type="text"
          autocomplete="off"
          [ngClass]="{
            invalid:
              !contactForm.controls['body'].valid &&
              contactForm.controls['body'].touched,
            sent: result && sent,
            error: !result && sent
          }"
        ></textarea>
      </div>
      <button
        class="btn cta-2"
        name="send epost"
        [disabled]="!contactForm.valid"
        [ngClass]="{
          disabled: !contactForm.valid,
          sent: sent
        }"
      >
        {{ sent && result ? "Melding sendt!" : "Send melding" }}
      </button>
    </form>
  </div>
  <div class="img">
    <img
      [src]="
        sanitizeImageUrl(
          '../../../../assets/Images/Home/about/640/Informasjon-om-digitalbyrå-eksperter-Moderna-Media-seo.webp'
        )
      "
      alt=""
    />
  </div>
</section>
