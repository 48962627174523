<section
  class="services"
  id="tjenester"
  aria-label="Våre tjenester for bedrifter"
>
  <div class="wrapper">
    <div class="titles">
      <p class="subtitle">Våre tjenester</p>
      <h2 class="title">Invester i vekst med våre beste tjenester</h2>
    </div>
    <div class="services">
      <!-- Nettsider -->
      <a
        routerLink="/tjenester/bedrift/utvikling/hjemmeside-bedrift"
        class="service"
        title="nettside for bedrift"
      >
        <div class="service-container">
          <div class="serivice-container-inner">
            <h3>Nettsider</h3>
            <div class="bg">
              <picture>
                <img
                  [src]="
                    sanitizeImageUrl(
                      '../../../../assets/Images/Home/tjenester/400/Liten butikk eier som har fått hjelp med digitale tjenester som hjemmeside og design.webp'
                    )
                  "
                  alt="En utvikler som lager hjemmeside for bedrift i Oslo når han jobber i et digitalbyrå"
                />
              </picture>
              <div class="shadow"></div>
            </div>
            <p class="usp-text">Øk din online tilstedeværelse</p>
            <p class="service-information">
              Design og teknisk oppsett av moderne og brukervennlige hjemmesider
              som trekker oppmerksomhet, bygger tillit og øker din omsetning.
            </p>
          </div>
          <p class="cta-link">Se vår hjemmeside tjeneste</p>
        </div>
      </a>
      <!-- Nettsider slutt -->
      <!-- Programvare  -->
      <a
        routerLink="/tjenester/bedrift/utvikling/programvare"
        class="service"
        title="programvare utvikling for bedrifter"
      >
        <div class="service-container">
          <div class="serivice-container-inner">
            <h3>Programvare</h3>
            <div class="bg">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Home/tjenester/500/To personer som jobber med teknologi i en startup og jobber med digitalisering.webp'
                  )
                "
                alt="Programvare utvikling for bedrifter gjennom et digitalbyrå"
              />
              <div class="shadow"></div>
            </div>
            <p class="usp-text">Systemer som sparer deg penger</p>
            <p class="service-information">
              Få programvare som automatiserer dine daglige gjøremål å gjør
              livet ditt enklere, samtidig som du reduserer kostnader.
            </p>
          </div>
          <p class="cta-link">Se vår programvare tjeneste</p>
        </div>
      </a>
      <!-- Programvare slutt -->
      <!-- Design -->
      <a
        routerLink="/tjenester/bedrift/design"
        class="service"
        title="Design tjenester for bedrifter"
      >
        <div class="service-container">
          <div class="serivice-container-inner">
            <h3>Design</h3>
            <div class="bg">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Home/tjenester/400/Restaurant eier som moderniseres med hjemmeside.webp'
                  )
                "
                alt="Grafisk design for logo og webdesign"
              />
              <div class="shadow"></div>
            </div>
            <p class="usp-text">
              Imponer og engasjer med din visuelle identitet
            </p>
            <p class="service-information">
              Merkevarebygging, design av logo, visittkort, og generell grafisk
              design til nettsider, kampanjer eller markedsføringsmateriell.
            </p>
          </div>
          <p class="cta-link">Se våre design tjenester</p>
        </div>
      </a>
      <!-- Design slutt -->
      <!-- SEO  -->
      <a
        routerLink="/tjenester/bedrift/seo/søkemotoroptimalisering"
        class="service"
        title="SEO tjenester for bedrifter"
      >
        <div class="service-container">
          <div class="serivice-container-inner">
            <h3>SEO</h3>
            <div class="bg">
              <img
                [src]="
                  sanitizeImageUrl(
                    '../../../../assets/Images/Home/tjenester/500/Dame som har fått hjelp med programvare og automatisering i blomsterbutikk.webp'
                  )
                "
                alt="Et seo byrå som jobber med søkemotor optimalisering"
              />
              <div class="shadow"></div>
            </div>
            <p class="usp-text">La kundene dine finne deg på nett</p>
            <p class="service-information">
              Søkemotoroptimalisering lar kunde finne deg før de finner dine
              konkurrenter. Med organisk vekst kan vi hjelpe deg å øke
              inntektene dine.
            </p>
          </div>
          <p class="cta-link">Se våre SEO tjenester</p>
        </div>
      </a>
      <!-- Programvare slutt -->
    </div>
    <!-- <div class="buttons">
      <a routerLink="/" hreflang="no" class="btn shadow section-cta">
        <p>
          <img src="../../../../assets/Images/Icons/se_mer.svg" alt="" />
          Se alle våre tjenester
        </p>
      </a>
    </div> -->
  </div>
</section>
