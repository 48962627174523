<section class="info {{ data.color }}">
  <div class="info-container {{ data.position }}">
    <div class="image-container">
      <img [src]="sanitizeImageUrl(data.img.url)" alt="{{ data.img.alt }}" />
    </div>
    <div class="text-container">
      <article>
        <div class="header">
          <p class="subtitle-gradient blue">{{ data.header.subtitle }}</p>
          <h2 class="title-bold">{{ data.header.title }}</h2>
        </div>
        <p class="text-section" *ngFor="let text of data.text">
          {{ text }}
        </p>
        <ul class="usp-list">
          <li *ngFor="let li of data.listItems">{{ li }}</li>
        </ul>
        <p class="text-section" *ngFor="let text of data.textSecond">
          {{ text }}
        </p>
      </article>
      <div class="buttons">
        <a href="#kontakt" class="btn cta shadow"
          ><p>Book et uforpliktet møte</p></a
        >
        <a [routerLink]="data.button.url" class="btn cta-2"
          ><p>{{ data.button.text }}</p></a
        >
      </div>
    </div>
  </div>
</section>
