<div class="component">
  <div class="card">
    <div class="img-wrapper">
      <span class="cover"></span>
      <img
        class="bg-img"
        src="../../../../assets/Images/testimonials/solaparkering/Parkering-sola-flyplassparkering-på-sola.jpg"
        alt=""
      />
      <a
        href="solaparkering.no"
        target="_blank"
        title="sola parkering hjemmeside"
        >Sola parkering</a
      >
    </div>
    <div class="person-container">
      <div class="person-wrapper">
        <img
          class="person svein"
          src="../../../../assets/Images/testimonials/solaparkering/31fa76d5-35b6-468c-8c42-4291d6716f5e.webp"
          alt="Kunde av moderna media tjenester"
        />
      </div>
    </div>

    <div class="content">
      <div class="header">
        <div class="row">
          <p>Svein Magnar</p>
          <p>Daglig leder</p>
          <p>Bedrifts Nettside</p>
          <p>SEO & Markedsføring</p>
        </div>
      </div>
      <p class="content-text">
        Vi fikk designet og utviklet en nettside for Sola Parkering som var i
        tråd med vår identitet og som snakker godt til kundene. Vi er veldig
        fornøyde med tjeneste og servicen som fulgte.
        <br />
        Jeg kan trygt anbefale Moderna Media til andre!
      </p>
      <div class="rating">
        <div class="star-container">
          <img
            src="../../../../assets/Images/Icons/testiomonials/star-icon.svg"
            alt=""
          />
        </div>
        <div class="star-container">
          <img
            src="../../../../assets/Images/Icons/testiomonials/star-icon.svg"
            alt=""
          />
        </div>
        <div class="star-container">
          <img
            src="../../../../assets/Images/Icons/testiomonials/star-icon.svg"
            alt=""
          />
        </div>
        <div class="star-container">
          <img
            src="../../../../assets/Images/Icons/testiomonials/star-icon.svg"
            alt=""
          />
        </div>
        <div class="star-container">
          <img
            src="../../../../assets/Images/Icons/testiomonials/star-icon.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
