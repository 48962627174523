<section class="design-system">
  <div class="wrapper">
    <div class="container h-tag">
      <h1>h1 -- Lorem ipsum dolor sit amet.</h1>
      <h2>h2 -- Lorem ipsum dolor sit amet.</h2>
      <h3>h3 -- Lorem ipsum dolor sit amet.</h3>
      <h4>h4 -- Lorem ipsum dolor sit amet.</h4>
      <h5>h5 -- Lorem ipsum dolor sit amet.</h5>
      <h6>h6 -- Lorem ipsum dolor sit amet.</h6>
    </div>
    <div class="container misc-tag">
      <div class="wrap">
        <p>p .subtitle-gradient.blue</p>
        <p class="subtitle-gradient blue">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>p .subtitle-gradient</p>
        <p class="subtitle-gradient">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>p .title-bold</p>
        <p class="title-bold">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>p text-gradient</p>
        <p class="text-gradient">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>p text-glow</p>
        <p class="text-glow">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>p subtitle</p>
        <p class="subtitle">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>p subtitle large</p>
        <p class="subtitle large">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>p supert-title</p>
        <p class="supert-title">Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="wrap">
        <p>h1 title</p>
        <h1 class="title">Lorem ipsum dolor sit amet.</h1>
      </div>
      <div class="wrap">
        <p>h1 super-title</p>
        <h1 class="super-title">Lorem ipsum dolor sit amet.</h1>
      </div>
      <div class="wrap">
        <p>p scream</p>
        <p class="scream">Lorem ipsum dolor sit amet.</p>
      </div>
    </div>
    <div class="container misc-tag">
      <p>P -- Lorem ipsum dolor sit amet.</p>
      <span>Span -- Lorem ipsum dolor sit amet.</span>
      <label>label -- Lorem ipsum dolor sit amet.</label>
      <input placeholder="Lorem ipsum dolor sit amet." />
      <textarea placeholder="Lorem ipsum dolor sit amet.">
        Lorem ipsum dolor sit amet.</textarea
      >
      <textarea placeholder="Lorem ipsum dolor sit amet."></textarea>
      <button>button -- Lorem ipsum dolor sit amet.</button>
    </div>
  </div>

  <div class="wrapper buttons">
    <div class="container buttons">
      <button class="btn shadow">btn btn shadow</button>
      <button class="btn cta shadow"><p>btn cta shadow</p></button>
      <button class="btn cta-2 shadow">btn cta-2 shadow</button>
      <button class="btn gradient shadow">btn gradient shadow</button>
      <button class="btn gradient purple shadow">
        btn gradient purple shadow
      </button>
      <button class="btn gradient orange">btn gradient orange shadow</button>
      <button class="btn gradient blue">btn gradient blue shadow</button>
    </div>
    <div class="container buttons">
      <button class="btn">btn btn</button>
      <button class="btn cta"><p>btn cta</p></button>
      <button class="btn cta-2">btn cta-2</button>
      <button class="btn gradient">btn gradient</button>
      <button class="btn gradient purple">btn gradient purple</button>
      <button class="btn gradient orange">btn gradient orange</button>
      <button class="btn gradient blue">btn gradient blue</button>
    </div>
    <div class="container buttons">
      <button class="btn hover">btn btn</button>
      <button class="btn cta hover"><p>btn cta</p></button>
      <button class="btn cta-2 hover">btn cta-2</button>
      <button class="btn gradient hover">btn gradient</button>
      <button class="btn gradient purple hover">btn gradient purple</button>
      <button class="btn gradient orange hover">btn gradient orange</button>
      <button class="btn gradient blue hover">btn gradient blue</button>
    </div>
    <div class="container buttons">
      <button class="btn active">btn btn</button>
      <button class="btn cta active"><p>btn cta</p></button>
      <button class="btn cta-2 active">btn cta-2</button>
      <button class="btn gradient active">btn gradient</button>
      <button class="btn gradient purple active">btn gradient purple</button>
      <button class="btn gradient orange active">btn gradient orange</button>
      <button class="btn gradient blue active">btn gradient blue</button>
    </div>
  </div>
  <div class="container boxes">
    <div class="wrap">
      <p>eleveation-1</p>
      <div class="box box-1"></div>
    </div>
    <div class="wrap">
      <p>eleveation-2</p>
      <div class="box box-2"></div>
    </div>
    <div class="wrap">
      <p>eleveation-3</p>
      <div class="box box-3"></div>
    </div>
    <div class="wrap">
      <p>eleveation-4</p>
      <div class="box box-4"></div>
    </div>
    <div class="wrap">
      <p>eleveation-5</p>
      <div class="box box-5"></div>
    </div>
    <div class="wrap">
      <p>box-shadow</p>
      <div class="box box-6"></div>
    </div>
    <div class="wrap">
      <p>box-shadow-small</p>
      <div class="box box-7"></div>
    </div>
  </div>
</section>
