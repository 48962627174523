<section
  class="about"
  aria-label="Om Moderna Media digitalbyrå"
  aria-labelledby="about-section-title"
>
  <div class="wrapper">
    <div class="image">
      <img
        [src]="
          sanitizeImageUrl(
            '../../../../assets/Images/Home/about/640/Informasjon-om-digitalbyrå-eksperter-Moderna-Media-seo.webp'
          )
        "
        alt="Digitalbyrå som jobber med bedrift nettsider og design"
      />
    </div>
    <div class="content">
      <div class="content-wrapper">
        <div class="titles">
          <h2 class="title" id="about-section-title">
            Om Moderna Media Digitalbyrå
          </h2>
          <p class="innhold">
            Moderna Media er et digitalt markedsføringsbyrå som spesialiserer
            seg på webdesign, SEO og grafisk design som styrker din digital
            tilstedeværelse og synlighet.
          </p>
          <p class="innhold">
            Våre tjenester hjelper deg å bli funnet av dine ideelle kunder,
            fremstå profesjonell på digitale kanaler og øke din sjanse for
            suksess på digitale flatter.
          </p>
        </div>
        <div class="liste">
          <div class="point">
            <div class="number">1</div>
            <div class="point-content">
              <p class="point-title">Vår visjon</p>
              <p class="point-text">
                Å være Norges beste digitalbyrå og den mest effektive
                leverandøren av digitale tjenester for norske bedrifter.
              </p>
            </div>
          </div>
          <div class="point">
            <div class="number">2</div>
            <div class="point-content">
              <p class="point-title">Vårt mål</p>
              <p class="point-text">
                Modernisere, digitalisere og synliggjøre bedrifter med uutnyttet
                potensial ved hjelp av våre digitale tjenester.
              </p>
            </div>
          </div>
          <div class="point">
            <div class="number">3</div>
            <div class="point-content">
              <p class="point-title">Vår historie</p>
              <p class="point-text">
                Moderne Media ble stiftet med et ønske om å tilby høykvalitets
                digitale tjenester til deg: En bedriftseier som ønsker
                webløsninger som presterer og skaper resultater.
              </p>
              <p class="point-text">
                Vår tidligere erfaring også som kunde, på den andre siden av
                markedet, har også visst oss at det finnes et hull i markedet
                når det kommer til aktører som kan levere tjenester til en
                realistisk og fornuftig pris og som samsvarer med kvaliteten på
                det de leverer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
