<app-tjenester-header [data]="dataHeader"></app-tjenester-header>
<app-tjenester-services
  [data]="dataServices"
  id="services"
></app-tjenester-services>
<app-tjenester-info-section
  [data]="dataInfoSection"
  id="grafisk_design"
></app-tjenester-info-section>
<app-tjenester-info-section
  [data]="dataInfoSection2"
  id="web_design"
></app-tjenester-info-section>
<app-tjenester-info-section
  [data]="dataInfoSection3"
  id="logo_design"
></app-tjenester-info-section>
<app-about-your-next-project></app-about-your-next-project>
