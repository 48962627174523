import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: [
    './testimonials.component.scss',
    './testimonials.desktop.component.scss',
  ],
})
export class TestimonialsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
